import React, { useEffect, useState } from 'react'
import Header from '../directives/header';
import Sidebar from '../directives/sidebar';
import Cookies from 'js-cookie'
import config from '../coreFIles/config';
import { getsettingsListAction, updatesettingsListsAction } from '../Action/action';
import toast, { Toaster } from 'react-hot-toast';
const loginData = (!Cookies.get('loginSuccessBondiAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessBondiAdmin'));


const Settings = () => {
    const [settingsList, setsettingsList] = useState([]);
	const [spinloader, setspinloader] = useState(0);  
    useEffect(() => {
		 if (!loginData?.email) {
            window.location.href = `${config.baseUrl}login`
        }
        getsettingsListAPI()
    }, []);

	const handleKeyPress = (e) => {
		// Allow only numeric values (0-9) and certain control keys
		const isValidInput = /^[0-9\b]+$/.test(e.key);

		if (!isValidInput) {
		  e.preventDefault();
		}
	  }; 
	  
    const getsettingsListAPI = async () => {
        let res = await getsettingsListAction({});
        if (res.success) {
            setsettingsList(res.data);
        }
    };

    const inputHandler = (e) => {
        const { name, value } = e.target
        setsettingsList((old) => {
            return { ...old, [name]: value }
        })
    }

    const updatesettings = async (e) => {
        e.preventDefault()
		setspinloader(1)
        let res = await updatesettingsListsAction(settingsList);
        if (res.success) {
            toast.success(res.msg);
            setspinloader(0)
        } else {
            toast.error(res.msg);
            setspinloader(0)
        }
    }

    return (

        <>
            <div class="wrapper">
                <Toaster />
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="container-full">
                        {/* Main content */}
                        <div className="content-header">
                            <div className="align-items-center">
                                <div className="me-auto">
                                    <h3 className="page-title mb-5 pb-2">Settings </h3>
                                </div>
                            </div>
                            <hr />
                        </div>
                        
					<section className="content">
					<div className="row">
						<div className="col-lg-12 col-12">
							<div className="box">
								<div className="box-header with-border">
									<h4 className="box-title">Change Settings</h4>					
								</div>
						<div className="row mt-20 mb-50 ml-15 mr-15">
							<div className="col-md-12">
								<div className="row">                                               
									<div className="col-md-3">
										<div className="form-group row mb-1">
											<label className="col-form-label col-md-12">Site name</label>
											<div className="col-md-12">
												<input type="text" onChange={inputHandler} value={settingsList.site_name} name="site_name" className="form-control" id="site_name" />
											</div>
										</div>
									</div>
									<div className="col-md-3">
										<div className="form-group row mb-1">
											<label className="col-form-label col-md-12">Site title</label>
											<div className="col-md-12">
												<input type="text" onChange={inputHandler} value={settingsList.site_title} name="site_title" className="form-control" id="site_title" placeholder="Enter Site title" />												
											</div>											
										</div>
									</div>
									<div className="col-md-2">
										<div className="form-group row mb-1">
											<label className="col-form-label col-md-12">Vip membership fee</label>
											<div className="col-md-12">
												<input type="text" onChange={inputHandler} value={settingsList.vip_membership_fee} name="vip_membership_fee" className="form-control" id="vip_membership_fee" onKeyPress={handleKeyPress} />											
											</div>											
										</div>
									</div>
									<div className="col-md-2">
										<div className="form-group row mb-1">
											<label className="col-form-label col-md-12">Free ad fee</label>
											<div className="col-md-12">
												<input type="text" onChange={inputHandler} value={settingsList.free_ad_fee} name="free_ad_fee" className="form-control" id="free_ad_fee" onKeyPress={handleKeyPress} />									
											</div>											
										</div>
									</div>
									<div className="col-md-2">
										<div className="form-group row mb-1">
											<label className="col-form-label col-md-12">Personal ad fee</label>
											<div className="col-md-12">
												<input type="text" onChange={inputHandler} value={settingsList.personal_ad_fee} name="personal_ad_fee" className="form-control" id="personal_ad_fee" onKeyPress={handleKeyPress} />									
											</div>											
										</div>
									</div>
									<div className="col-md-2">
										<div className="form-group row mb-1">
											<label className="col-form-label col-md-12">Show admin logs?</label>
											<div className="col-md-12">
												<select name='admin_log' className="form-control" id='admin_logError' onChange={inputHandler} value={settingsList?.admin_log}>
												<option value="0">No</option>
												<option value="1">Yes</option>
												</select>								
											</div>											
										</div>
									</div>
									<div className="col-md-2">
										<div className="form-group row mb-1">
											<label className="col-form-label col-md-12">Show user logs?</label>
											<div className="col-md-12">
												 <select name='user_log' className="form-control" id='user_logError' onChange={inputHandler} value={settingsList?.user_log}>
												<option value="0">No</option>
												<option value="1">Yes</option>
												</select>								
											</div>											
										</div>
									</div>
									
									
									<div className="col-md-12">
									  <div className="form-group row mb-1">
										<label className="col-form-label col-md-12">
											Site description
										</label>
										<div className="col-md-12">
											 <textarea
													className="form-control"
													type="text"
													rows={5}
													name="site_description"
													id='site_descriptionError'
													onChange={inputHandler} value={settingsList?.site_description}
													placeholder="Enter Site Description"/>
										</div>										
									</div>
									</div>			
									<div className="col-md-12">
									  <div className="form-group row mb-1">
										<label className="col-form-label col-md-12">
											Site keyword
										</label>
										<div className="col-md-12">
											 <textarea
													className="form-control"
													type="text"
													rows={5}
													name="site_keywords"
													id='site_keywordsError'
													onChange={inputHandler} value={settingsList?.site_keywords}
													placeholder="Enter Site keywords"/>
										</div>										
									</div>
									</div>			
									<div className="col-md-3">
										 <div className="form-group row mb-1 pt-28">
											<div className="col-md-12">
												{spinloader == '0' ?
												 <button type="submit" onClick={updatesettings} className='btn btn-primary'>Submit</button>
												:
												<button disabled className='btn btn-primary' >Submiting <i className="fa fa-spinner fa-spin validat"></i></button>
												}
											</div>
											</div>
									</div>
									</div>                                                   
									</div>
						</div>
						</div>
						</div>
						</div>
					</section>

                        
                        
                        
                        
                        
                        
                        
                        
                        
                    </div>
                </div>
            </div>
        </>
    )

}
export default Settings;
