import React, { useEffect, useState } from 'react'
import Header from '../directives/header'
import Footer from '../directives/footer'
import Sidebar from '../directives/sidebar'
import Cookies from 'js-cookie'
import config from '../coreFIles/config';
import { Link } from 'react-router-dom';
import { getDashboardStatisticsAction } from '../Action/action';
const loginData = (!Cookies.get('loginSuccessBondiAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessBondiAdmin'));

const Dashboard = () => {

  const [statistics, setStatistics] = useState({ totalUsers: 0, totalads: 0, totalNewads: 0});

  useEffect(() => {	
	  if (!loginData?.email) {
            window.location.href = `${config.baseUrl}login`
        }
    getDashboardStatistics();
  }, []);

  const getDashboardStatistics = async () => {
    let res = await getDashboardStatisticsAction({userId:loginData?.id});
    if (res.success) {
      let data = res.data;
      setStatistics((old) => {
        return {
          ...old,
          'totalUsers': data.totalUsers,
          'totalads': data.totalads,
          'totalNewads': data.totalNewads
        }
      })
    }
  }

  return (

    <>
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            <div className="content-header">
              <div className="d-flex align-items-center">
                <div className="me-auto">
                  <h3 className="page-title mb-5 pb-2">Dashboard</h3>
                </div>
              </div>
            </div>
            {/* Content Header (Page header) */}
            {/* Main content */}
            <section className="content pt-0">
              <div className="row">
                <div className="col-xl-12 col-12">
                  <div className="row">
                  { loginData?.id !=1 ? '':
                    <div className="col-lg-4 col-12">
                      <div className="box">
                        <div className="box-body">
                          <div className="no-line-chart d-flex align-items-end justify-content-between">
                            <div>
                              <p className="mb-0"><h4>Total Users</h4></p>
                              <p className="mb-0">
                                <h5><Link to={`${config.baseUrl}users/`}>
									{statistics.totalUsers}
									</Link>
                                </h5>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
				}
                    <div className="col-lg-4 col-12">
                      <div className="box">
                        <div className="box-body">
                          <div className="no-line-chart d-flex align-items-end justify-content-between">
                            <div>
                              <p className="mb-0"><h4>Total Ads</h4></p>
                              <p className="mb-0">
                                <h5><Link to={`${config.baseUrl}ads/`}>{statistics.totalads}</Link></h5>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-12">
                      <div className="box">
                        <div className="box-body">
                          <div className="no-line-chart d-flex align-items-end justify-content-between">
                            <div>
                              <p className="mb-0"><h4>Today New Ads</h4></p>
                              <p className="mb-0">                               
                                <h5><Link to={`${config.baseUrl}ads/`}>{statistics.totalNewads}</Link></h5>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>                    
                   
                  </div>
                </div>
              </div>
            </section>
            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>


  )

}
export default Dashboard;
