import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import toast, { Toaster } from "react-hot-toast";
import JoditEditor from "jodit-react";
import config from "../coreFIles/config";
import Cookies from 'js-cookie'
import { createPlanAction } from "../Action/action";
const loginData = (!Cookies.get('loginSuccessBondiAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessBondiAdmin'));


const Addplan = () => {
	
	const editorHeight = '500px'; 
	const [spinloader, setspinloader] = useState(0);  
	const [validatioError, setvalidatioError] = useState({});	
	const [plandata, setplandata] = useState({
        name: '',
        price: '',
        type: '2',
        days: '',
        description: '',
        status: ''             
    });
   
  
   useEffect(() => {
	    if (!loginData?.email) {
            window.location.href = `${config.baseUrl}login`
        }      
    }, [])     
   

  const inputHandler = async (e) => {
    const { name, value } = e.target;
    setplandata((old) => {
      return { ...old, [name]: value };
    });
  };
  
  const descriptionHandler = async (e) => {
		setplandata((old) => {
		  return { ...old, 'description': e }
		})
	  }
  
 
  
  function validate() {	 
	let nameError 	= "";
	let priceError 	= "";    
    let typeError 	= "";
    let daysError 	= "";    
    let statusError 	= "";    
       
    if (plandata.name === "") {
		nameError = "Name is required.";
    }  
    if (plandata.price === "") {
		priceError = "Price is required.";
    }     
    if (plandata.type === "") {
		typeError = "Plan type is required.";
    } 
    if (plandata.days === "") {
		daysError = "Plan days are required.";
    }    
    if (plandata.status === "") {
		statusError = "Status is required.";
    }    
    
    if (nameError || priceError || typeError || daysError || statusError ) {
		setvalidatioError({ nameError, priceError, typeError, daysError, statusError });
		return false;
    } else {
		setvalidatioError({ nameError, priceError, typeError, daysError, statusError });
        return true;
    }
  }
  const handleKeyPress = (e) => {
		// Allow only numeric values (0-9) and certain control keys
		const isValidInput = /^[0-9\b]+$/.test(e.key);

		if (!isValidInput) {
		  e.preventDefault();
		}
	  };  
	
  
  const createPlan = async (e) => {
        e.preventDefault()
        const isValid = validate();
        console.log(isValid);
        if (!isValid) {		
        }
        else {
            setspinloader(1);           
			let res = await createPlanAction(plandata);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}plans`;
				}, 2000);
			}			 
			else {
				toast.error(res.msg);
			}
		} 
	}
  
  return (
        <>
            <div className="wrapper">
                <Header />
                <Toaster />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="container-full">
                        <div className="content-header">
                            <div className="d-flex align-items-center">
                                <div className="me-auto">
                                    <h3 className="page-title mb-5 pb-2">Create Plan</h3>
                                </div>
                            </div>
                            <hr />
                        </div>
                        {/* Content Header (Page header) */}

                        {/* Main content */}
<section className="content">
	<div className="row">
		<div className="col-lg-12 col-12">
			<div className="box">
				<div className="box-header with-border">
					<h4 className="box-title">Create Plan</h4>
					<a href={`${config.baseUrl}plans`} className="btn btn-sm btn-primary add_btn">Back</a> 
				</div>
<div className="row mt-20 mb-50 ml-15 mr-15">
<form onSubmit={createPlan}>
	<div className="col-md-12">
		<div className="row">                                               
			<div className="col-md-4">
				<div className="form-group row mb-1">
					<label className="col-form-label col-md-12">Plan name <span className="req-star">*</span></label>
					<div className="col-md-12">
						<input className="form-control" type="text" name="name" id='nameError' onChange={inputHandler} placeholder="Enter plan name"/>
					</div>
					<span className="validationErr">{validatioError.nameError}</span>
				</div>
			</div>
			<div className="col-md-2">
				<div className="form-group row mb-1">
					<label className="col-form-label col-md-12">Plan price <span className="req-star">*</span></label>
					<div className="col-md-12">
						<input className="form-control" type="text" name="price" id='priceError' onChange={inputHandler} placeholder="Enter plan price" onKeyPress={handleKeyPress} />
					</div>
					<span className="validationErr">{validatioError.priceError}</span>
				</div>
			</div>
			<div className="col-md-2">
				<div className="form-group row mb-1">
					<label className="col-form-label col-md-12">How many days? <span className="req-star">*</span></label>
					<div className="col-md-12">
						<input className="form-control" type="text" name="days" id='daysError' onChange={inputHandler} placeholder="Enter plan days" onKeyPress={handleKeyPress} />
					</div>
					<span className="validationErr">{validatioError.daysError}</span>
				</div>
			</div>
			<div className="col-md-2">
				<div className="form-group row mb-1">
					<label className="col-form-label col-md-12">Status <span className="req-star">*</span></label>
					<div className="col-md-12">
						<select name='status' className="form-control" id='statusError' onChange={inputHandler}>
						<option value="">Select status</option>
						<option value="1">Active</option>
						<option value="0">In-active</option>
						</select>
					</div>
					<span className="validationErr">{validatioError.statusError}</span>
				</div>
			</div>			
			<div className="col-md-12">
				<div className="form-group row mb-1">
					<label className="col-form-label col-md-12">Description</label>
					<div className="col-md-12">
						<JoditEditor
                              onChange={descriptionHandler}
                              value=''                              
                              name="description"
                              id="description"
                               height="400px"
                              style={{ height: editorHeight }}
                              className="form-control"
                              placeholder="Enter description"
                            />						
					</div>					
				</div>
			</div>
			<div className="col-md-12">
				<br />
				<div className="text-center pull-left">
					{spinloader == '0' ?
					<button className='btn btn-primary' >Submit</button>
					:
					<button disabled className='btn btn-primary' >Submiting <i className="fa fa-spinner fa-spin validat"></i></button>
					}
				</div>
			</div>
			</div>                                                   
			</div>
</form>
</div>
</div>
</div>
</div>
</section>
{/* /.content */}
</div>
</div>

                <Footer />
            </div>
        </>
    );
};
export default Addplan;
