import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import toast, { Toaster } from "react-hot-toast";
import JoditEditor from "jodit-react";
import config from "../coreFIles/config";
import Cookies from 'js-cookie'
import { updateEmailtemplateAction, getEmailtemplateAction, getTemplateTypeAction } from "../Action/action";
import avt from '../assets/avata_profile.jpg'
import { Link, useParams } from 'react-router-dom';

const loginData = (!Cookies.get('loginSuccessBondiAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessBondiAdmin'));
const Editemailtemplate = () => {
    const editorHeight = '400px';
    const { id } = useParams();
	const [spinloader, setspinloader] = useState(0);
	const [validatioError, setvalidatioError] = useState({});
    const [templateTypeList, setTemplateTypeList] = useState('');
	const [templatedata, settemplatedata] = useState({});

	useEffect(() => {
		if (!loginData?.email) {
			window.location.href = `${config.baseUrl}login`
		}
		
		getEmailTemplateDetail();
		getTypelist();
	}, [])

	const getEmailTemplateDetail = async () => {
		let res = await getEmailtemplateAction({id:id});
		if (res.success) {
			settemplatedata(res.data);
		}
		else
		{
			settemplatedata([]);
		}
	}

	const getTypelist = async () => {
		let res = await getTemplateTypeAction();
		if (res.success) {
			let data = res.data;
			
			setTemplateTypeList(data);	
		}
		else
		{
			setTemplateTypeList([]);	
		}
	}

	const inputHandler = async (e) => {
		const { name, value } = e.target;
		settemplatedata((old) => {
			return { ...old, [name]: value };
		});
	};

    const descriptionHandler = async (e) => {
		settemplatedata((old) => {
		  return { ...old, 'body': e }
		})
    }

	function validate() {
		let titleError = "";
		let master_idError = "";
		let bodyError = "";
        
        console.log(templatedata);
        
		if (templatedata.title === "") {
			titleError = "Subject is required.";
		}
		
		if (templatedata.master_id === "") {
			master_idError = "Template type is required.";
		}
		
		if (templatedata.body === "") {
			bodyError = "Template body is required.";
		}
		
		
		if (titleError || master_idError || bodyError ) {
			setvalidatioError({
				titleError, master_idError, bodyError
			});
			
			return false;
		}
		else {
			setvalidatioError({
				titleError, master_idError, bodyError
			});
			return true;
		}
	}
	
	const updateEmailtemplate = async (e) => {
		e.preventDefault()
		const isValid = validate();
		console.log(isValid);
		if (!isValid) {
		}
		else {
			setspinloader(1);
			let res = await updateEmailtemplateAction(templatedata);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}emaillisting`;
				}, 2000);
			}
			else {
				setspinloader(0);
				toast.error(res.msg);
			}
		}
	}
	
	return (
		<>
			<div className="wrapper">
				<Header />
				<Toaster />
				<Sidebar />
				<div className="content-wrapper">
					<div className="container-full">
						<div className="content-header">
							<div className="d-flex align-items-center">
								<div className="me-auto">
									<h3 className="page-title mb-5 pb-2">Edit Email Template</h3>
								</div>
							</div>
							<hr />
						</div>
						{/* Content Header (Page header) */}

						{/* Main content */}
						<section className="content">
							<div className="row">
								<div className="col-lg-12 col-12">
									<div className="box">
										<div className="box-header with-border">
											<h4 className="box-title">Edit Email Template</h4>
											<a href={`${config.baseUrl}emaillisting`} className="btn btn-sm btn-primary add_btn">Back</a>
										</div>
										<div className="row mt-20 mb-50 ml-15 mr-15">
											<form onSubmit={updateEmailtemplate}>
												<div className="col-md-12">
													<div className="row">
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																	Template Type <span className="req-star">*</span>
																</label>
																<div className="col-md-12">
																	<select name='master_id' className="form-control" id='master_idError' onChange={inputHandler} value={templatedata?.master_id}>
																		<option value="">Select</option>
																		{templateTypeList.length> 0 ?
																			templateTypeList.map(type => (
																				<option value={type.id}>{type.system_keyword}</option>
																			)) : ''
																		}
								
                                									</select>
                                                                    <span className="validationErr">
                                                                        {validatioError.master_idError}
                                                                    </span>
																</div>
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																	Ad Title <span className="req-star">*</span>
																</label>
																<div className="col-md-12">
																	<input className="form-control" type="text" name="title" id='titleError' onChange={inputHandler} placeholder="Enter title" value={templatedata?.title} />
																</div>
																<span className="validationErr">
																	{validatioError.titleError}
																</span>
															</div>
														</div>
														
														<div className="col-md-12">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">Description <span className="req-star">*</span> </label>
																<div className="col-md-12">
																	<JoditEditor
																		onChange={descriptionHandler}
																		value={templatedata?.body}
																		name="body"
																		id="bodyError"
																		style={{ height: editorHeight }}
																		className="form-control"
																		placeholder="Enter description"
																		/>						
																</div>	
                                                                <span className="validationErr">
																	{validatioError.bodyError}
																</span>				
															</div>
														</div>
														
														<div className="col-md-12">
															
															<br />
															<div className="text-center pull-left">
																{spinloader == '0' ?
																<button className='btn btn-primary'>Submit</button>
																:
																<button disabled className='btn btn-primary'>Submiting <i className="fa fa-spinner fa-spin validat"></i></button>
																}
															</div>
														</div>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</section>
						{/* /.content */}
					</div>
				</div>

				<Footer />
			</div>
		</>
	);
};
export default Editemailtemplate;