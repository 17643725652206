import React, { useEffect, useState } from 'react'
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import Header from '../directives/header';
import Footer from '../directives/footer';
import Sidebar from '../directives/sidebar';
import ReactDatatable from '@ashvin27/react-datatable';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {getvipMemberslistAction } from '../Action/action'
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
const loginData = (!Cookies.get('loginSuccessBondiAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessBondiAdmin'));

const Vipmemberlist = () => {
	const [vipMembersList, setvipMembersList]	= useState([]);
	
	useEffect(() => {
		if (!loginData?.email) {
			window.location.href = `${config.baseUrl}login`
		}
		
		getvipMemberslist();
	}, [])
	
	const getvipMemberslist = async () => {
		let res = await getvipMemberslistAction();
		if (res.success) {
			let data = res.data;
			setvipMembersList(data);
		}
		else
		{
			setvipMembersList([]);
		}
	}
	
	const columns = [
		{
			key: "Sno.",
			text: "#",
			cell: (row, index) => index + 1,
		},
		{
			key: "membername",
			text: "User Name"
		},
		{
			key: "email",
			text: "Email",
			cell: (item) => {
			   return (
					
					<>
						<Link to={`${config.baseUrl}edit-user/` + item.member_id}>
								{item.email}
						</Link>
					</>	
					);		
			  },
		},
		{
			key: "recevied_on",
			text: "Recevied On",
			cell: (item) => {
				return (
					<>
						{ item.recevied_on != "" ? moment(item.recevied_on).format('DD/MM/YYYY') : '' }
					</>
				)
			}
		},
		{
			key: "from_date",
			text: "From Date",
			cell: (item) => {
				return (
					<>
						{ item.from_date != "" ? moment(item.from_date).format('DD/MM/YYYY') : '' }
					</>
				)
			}
		},
		{
			key: "to_date",
			text: "To Date",
			cell: (item) => {
				return (
					<>
						{ item.to_date != "" ? moment(item.to_date).format('DD/MM/YYYY') : '' }
					</>
				)
			}
		},
        {
			key: "payment_amount",
			text: "Amount Paid"
		},
		{
			key: "currency",
			text: "Currency"
		},
		{
			key: "updated_by",
			text: "Updated By",
            cell: (item) => {
				return (
					<>
						{ item.updated_by == 1 ? 'Admin' : item.membername }
					</>
				)
			}
		}
	];

	const configForTable = {
		page_size: 10,
		length_menu: [10, 20, 50],
		show_filter: true,
		show_pagination: true,
		pagination: "advance",
		button: {
		excel: false,
		print: false,
		},
	};

	return (
		<>
			<div className="wrapper">
				<Toaster />
				<Header />
				<Sidebar />
				<div className="content-wrapper">
					<div className="container-full">
						{/* Main content */}
						<div className="content-header">
							<div className="d-flex align-items-center">
								<div className="me-auto">
									<h3 className="page-title mb-5 pb-2">VIP Membership Payment's</h3>
								</div>
							</div>
							<hr />
						</div>
						<section className="content">
							<div className="row">
								<div className="col-lg-12 col-12">
									<div className="box">
										<div className="box-header with-border">
											<div className="row mb-15">
												<h4 className="box-title col-md-12">VIP Membership Payment's ({vipMembersList.length})</h4>
												
											</div>
										</div>
										
										<div className="box-body">
											<ReactDatatable
												config={configForTable}
												records={vipMembersList}
												columns={columns}
											/>
										</div>
									</div>
								</div>
							</div>
						</section>                       
					</div>
				</div>      	
				<Footer />
			</div>
		</>
	)
}
export default Vipmemberlist;
