import React, { useEffect, useState } from 'react';
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import Header from '../directives/header';
import Footer from '../directives/footer';
import Sidebar from '../directives/sidebar';
import ReactDatatable from '@ashvin27/react-datatable';
import { Link } from 'react-router-dom';
import {getSpamlistAction, spamDeleteAction, spamBlockUnBlockAction } from '../Action/action'
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
const loginData = (!Cookies.get('loginSuccessBondiAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessBondiAdmin'));

const Spamlist = () => {
    const [spamList, setSpamList] 				= useState([]);
	const [page, setPage] 						= useState(1);	
	
    useEffect(() => {
		  if (!loginData?.email) {
            window.location.href = `${config.baseUrl}login`
        }
        getSpamlist(); 
       
    }, [])
   
   
  	const getSpamlist = async () => {
		let res = await getSpamlistAction();
		if (res.success) {
			let data = res.data;			
			setSpamList(data);	
		}
		else
		{
			setSpamList([]);	
		}
	}
      
  	const spamDelete = async (spam_id) => {
		Swal.fire({
			title: 'Are you sure? ',
			text: "You want to Delete this spam text!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
		}).then(async (result) => {
			if (result.isConfirmed) {
				let res = await spamDeleteAction({'spam_id':spam_id});
				if (res.success) {
					toast.success(res.msg);
					getSpamlist();
				} else {
					toast.error(res.msg);
				}
			}
		});
	}
	
 	const spamBlockUnBlock = async (id, is_active) => {
        Swal.fire({
            title: 'Are you sure?',
            text: is_active == 0 ? "You want to deactivate this spam text!”" : "You want to activate this spam text!”",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: is_active == 0 ? 'Yes, deactivate it!' : 'Yes, activate it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
				let res = await spamBlockUnBlockAction({ 'id': id, 'is_active': is_active });
				if (res.success) {
					getSpamlist();
					Swal.fire(
						is_active == 0 ? 'Deactivate!' : 'Activate',
						res.msg,
						'success'
					)
				} else {
					Swal.fire(
						'Failed!',
						res.msg,
						'error'
					)
				}
			}
		})
	}
	
	 const columns = [
    {
      key: "Sno.",
      text: "#",
      cell: (row, index) => index + 1,
    },
    {
      key: "spamtext",
      text: "Spam Text",
      className: 'break-words',     
    },
    {
      key: "isactive",
      text: "Status",
      cell: (item) => {
			return (
					<> 
						{ item.isactive === 1 ? 'Active' : 'Inactive' }
					</>
			);
		}      
    },
    {
	key: "isactive",
	text: "Action",
	cell: (item) => {
		return (
			
			<>
				<div className="btn-group mb-5">
						{item.isactive === 0 ?
							<button type="button" className="waves-effect waves-light btn btn-danger btn-sm" onClick={() => spamBlockUnBlock(item.id, 1)}><i className='fa fa-unlock'></i> Inactive</button>
							: item.isactive === 1 ?
								<button type="button" className="waves-effect waves-light btn btn-primary btn-sm" onClick={() => spamBlockUnBlock(item.id, 0)}><i className='fa fa-ban'></i> Active</button>
								:
								null
						} &nbsp;&nbsp;&nbsp;
						<Link to={`${config.baseUrl}edit-spamtext/${item.id}`}>
							<button className='btn-sm btn-primary'><i className='fa fa-pencil'></i> Edit</button>
						</Link>&nbsp;&nbsp;&nbsp;
						<button type="button" className="waves-effect waves-light btn btn-danger btn-sm" onClick={() => spamDelete(item.id)}><i className='fa fa-trash'></i> Delete</button>
					</div>
			</>
		);
	}
    }
  ];

  const configForTable = {
    page_size: 50,
    length_menu: [50, 100, 150],
    show_filter: true,
    show_pagination: true,
    pagination: "advance",
    button: {
      excel: false,
      print: false,
    },
  };
  

    return (
		<>
			<div className="wrapper">
				<Toaster />
				<Header />
				<Sidebar />
				<div className="content-wrapper">
					<div className="container-full">
						{/* Main content */}
						<div className="content-header">
							<div className="d-flex align-items-center">
								<div className="me-auto">
									<h3 className="page-title mb-5 pb-2">Spam Filter List</h3>
								</div>
							</div>
							<hr />
						</div>
						<section className="content">
							<div className="row">
								<div className="col-lg-12 col-12">
									<div className="box">
										<div className="box-header with-border">
											<div className="row mb-15">
												<h4 className="box-title col-md-3">Spam Text List ({spamList.length})</h4>
												<div className="col-md-9 text-right">
													<a href={`${config.baseUrl}add-spamtext`} className="btn btn-sm btn-primary add_btn"><i className='fa fa-plus'></i> Add Spam Text</a>
												</div>
											</div>
										</div>
										<div className="box-body">
											<ReactDatatable
                                                config={configForTable}
                                                records={spamList}
                                                columns={columns}
                                            />
										</div>
									</div>
								</div>
							</div>
						</section>                       
					</div>
				</div>      	
				<Footer />
			</div>
		</>
    )

}
export default Spamlist;
