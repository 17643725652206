import React, { useEffect, useState } from 'react'
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import Header from '../directives/header';
import Footer from '../directives/footer';
import Sidebar from '../directives/sidebar';
import CustomPagination from './CustomPagination';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {getUserslistAction, getTotalUsersListAction, UserDeleteAction, UserBlockUnBlockAction } from '../Action/action'
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
const loginData = (!Cookies.get('loginSuccessBondiAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessBondiAdmin'));

const Users = () => {
    const [UsersList, setUsersList] 				= useState([]);
	const [page, setPage] 							= useState(1); 
	const [totalPages, settotalPages] 				= useState(0);
	const [totalRecords, settotalRecords] 			= useState(0);
	const [pagingspinloader, setpagingspinloader] 	= useState(0);	
	const [filterspinloader, setfilterspinloader] 	= useState(0);	
	const [emailFilter, SetemailFilter] 			= useState('');	
	const [userTypeFilter, SetuserTypeFilter] 		= useState('');	
	const [userMemTypeFilter, SetuserMemTypeFilter]	= useState('');	
	const [startIndex, setstartIndex] 				= useState(0);	
	
    
    useEffect(() => {
		  if (!loginData?.email) {
            window.location.href = `${config.baseUrl}login`
        }        
        getTotalUsersListAPI();
        getUserslist(); 
       
    }, [])
    
    const getTotalUsersListAPI = async () => {
        let res = await getTotalUsersListAction({email:emailFilter, userType:userTypeFilter, userMemType:userMemTypeFilter});
        if (res.success) {
            settotalPages(res.data);
            settotalRecords(res.totalRecords);
            setpagingspinloader(0);            
		}
        else
        {
			settotalPages(0);
			settotalRecords(0);		
			setpagingspinloader(0);	
		}
		
		
    }
   
  const getUserslist = async () => {
		let res = await getUserslistAction({email:emailFilter, userType:userTypeFilter, userMemType:userMemTypeFilter,page:page});
		if (res.success) {
			let data = res.data;			
			setUsersList(data);	
		}
		else
		{
			setUsersList([]);	
		}
	}
      
  const UserDelete = async (user_id) => {
		Swal.fire({
			title: 'Are you sure? ',
			text: "You want to Delete this User!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
		}).then(async (result) => {
			if (result.isConfirmed) {
				let res = await UserDeleteAction({'user_id':user_id});
				if (res.success) {
					toast.success(res.msg);
					getUserslist();
				} else {
					toast.error(res.msg);
				}
			}
		});
	}
	
 const UserBlockUnBlock = async (id, is_active) => {
        Swal.fire({
            title: 'Are you sure?',
            text: is_active == 0 ? "You want to deactivate this user!”" : "You want to activate this user!”",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: is_active == 0 ? 'Yes, deactivate it!' : 'Yes, activate it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
               let res = await UserBlockUnBlockAction({ 'id': id, 'is_active': is_active });
                if (res.success) {
                     getUserslist();
                    Swal.fire(
                        is_active == 0 ? 'Deactivate!' : 'Activate',
                        res.msg,
                        'success'
                    )
                } else {
                    Swal.fire(
                        'Failed!',
                        res.msg,
                        'error'
                    )
                }
            }
        })
    }  
		
  const onPageChanged = async (data)  => {
		const { currentPage, totalPages, pageLimit } = data;
		const offset = (currentPage - 1) * pageLimit;
		let res = await getUserslistAction({email:emailFilter, userType:userTypeFilter, userMemType:userMemTypeFilter,page:offset});
        if (res.success) {
            setUsersList(res.data);
        }
        else
        {
			setUsersList([]);	
		}

  }	
  
  const SetemlFilter = async (e) => {
        SetemailFilter(e.target.value);        
    }
  const SettyFilter = async (e) => {
        SetuserTypeFilter(e.target.value);        
    }  
  const SetMtyFilter = async (e) => {
        SetuserMemTypeFilter(e.target.value);        
    }    
  const FilterUsers = async (e) => {
	  setfilterspinloader(1);
		setPage(1);	
        getTotalUsersListAPI();
        getUserslist(); 
        setfilterspinloader(0);      
    }
    
     const SrNo = (index) => {
		return startIndex + index + 1;
	  }; 
 
    
  const ClearFilter = async (e) => {
	  SetemailFilter('');  
	  SetuserTypeFilter(''); 
	  SetuserMemTypeFilter('');       
	  setPage(1);	
      let res = await getTotalUsersListAction({email:'', userType:'', userMemType:''});
        if (res.success) {
            settotalPages(res.data);
            settotalRecords(res.totalRecords);
            setpagingspinloader(0);            
		}
        else
        {
			settotalPages(0);
			settotalRecords(0);		
			setpagingspinloader(0);	
		}
      let list = await getUserslistAction({email:'', userType:'', userMemType:'', page:1});
		if (list.success) {
			setUsersList(list.data);	
		}
		else
		{
			setUsersList([]);	
		}      
    }
    

 
    return (

        <>
            <div className="wrapper">
                <Toaster />
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="container-full">
                        {/* Main content */}
                        <div className="content-header">
                            <div className="d-flex align-items-center">
                                <div className="me-auto">
                                    <h3 className="page-title mb-5 pb-2">Users List</h3>
                                 </div>
                            </div>
                            <hr />
                        </div>
                        <section className="content">
                            <div className="row">
                                <div className="col-lg-12 col-12">
                                    <div className="box">
                                        <div className="box-header with-border">
											<div className="row mb-15">
											<h4 className="box-title col-md-2">Users ({UsersList.length})</h4>
                                            
											  <div className="col-md-10 text-right">
											    <a href={`${config.baseUrl}add-user`} className="btn btn-sm btn-primary add_btn"><i className='fa fa-plus'></i> Add User</a>
											</div>
                                        </div>
                                         <div className="row">
                                            <div className="col-md-3 mb-3">
												<input type="text" name="emailFilter" class="form-control lh25" id="emailFilter" onChange ={SetemlFilter} placeholder="Enter email"/>												
                                             </div>                                           
                                             <div className="col-md-2 mb-3">
												<select name='userTypeFilter' className="form-control lh25" id='userTypeFilter' onChange={SettyFilter}>
													<option value="">Member type</option>
														<option value="1">Individual</option>
														<option value="0">Company</option>																			
												</select>
                                             </div>
                                             <div className="col-md-2 mb-3">
												<select name='userMemTypeFilter' className="form-control lh25" id='userMemTypeFilter' onChange={SetMtyFilter}>
													<option value="">Membership type</option>
													<option value="1"> Free</option>
													<option value="2"> Vip</option>							
												</select>
                                             </div>
                                            
                                           
                                                                                        
                                                                                         
											  <div className="col-md-3">	
											  <a href={`${config.baseUrl}users`}
										className="btn btn-sm btn-primary add_btn"><i className="fa fa-eraser" aria-hidden="true"></i> Clear</a>	
										
											  	{ filterspinloader == '0' ?
													  <>													  
												   <button className="btn btn-sm btn-primary add_btn mr-15" onClick={FilterUsers}><i className="fa fa-filter" aria-hidden="true"></i> Filter</button>
												   </>
												   :
													<>
													 <button disabled className="btn btn-sm btn-primary add_btn mr-15" >Geting... &nbsp; <i className="fa fa-spinner fa-spin validat"></i></button>
													  </>
												  }	
											   
                                                                                        
									   </div>								  
                                        </div>
                                         
                                        </div>
                                        <div className="box-body">
                                            
                                           <div className="table-responsive">
											  <table className="table table-bordered">
												<thead>
												  <tr>
													<th>#</th>
													<th>ID</th>
													<th>Name</th>
													<th>Type</th>
													<th>Email</th>									
													<th>Suburb</th>									
													<th>State</th>									
													<th>Code</th>
													<th>Register On</th>
													<th>Action</th>
												  </tr>
												</thead>
												<tbody>											
												
												 {UsersList.length > 0 ? (
													UsersList.map((item, index) => (
														<tr key={item.id}>
															<td>{SrNo(index)}</td>
															<td>{item.id}</td>
															<td>{item.fname} {item.lname}</td>
															<td>{item.membership_type === 1 ? 'Free' : 'Paid' }</td>
															<td>{item.email}</td>
															<td>{item.suburb}</td>
															<td>{item.state}</td>
															<td>{item.zip}</td>
															<td>{moment(item.registered_on).format('DD/MM/YYYY')}</td>
															<td>   
																<div className="btn-group mb-5">
																	{item.status === 0 ?
																		<button type="button" className="waves-effect waves-light btn btn-danger btn-sm" onClick={() => UserBlockUnBlock(item.id, 1)}><i className='fa fa-unlock'></i> Inactive</button>
																		: item.status === 1 ?
																			<button type="button" className="waves-effect waves-light btn btn-primary btn-sm" onClick={() => UserBlockUnBlock(item.id, 0)}><i className='fa fa-ban'></i> Active</button>
																			:
																			null
																	} &nbsp;&nbsp;&nbsp;
																	<Link to={`${config.baseUrl}edit-user/${item.id}`}>
																		<button className='btn-sm btn-primary'><i className='fa fa-pencil'></i> Edit</button>
																	</Link>&nbsp;&nbsp;&nbsp;
																	<button type="button" className="waves-effect waves-light btn btn-danger btn-sm" onClick={() => UserDelete(item.id)}><i className='fa fa-trash'></i> Delete</button>
																</div>
															</td>
														</tr>
													))
												) : (
													<tr>
														<td colSpan="10">No record found.</td>
													</tr>
												)}

												</tbody>
											  </table>	
											 {totalPages > 0 ?										  
											 <CustomPagination totalRecords={totalRecords} totalPages={totalPages} pageNeighbours={page} onPageChanged={onPageChanged}   />
											 : ''
										 }	
																				
											</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>                       
                    </div>
                </div>      	
                <Footer />
            </div>
        </>


    )

}
export default Users;
