import React, { useEffect, useState } from 'react'
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import Header from '../directives/header';
import Footer from '../directives/footer';
import Sidebar from '../directives/sidebar';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import ReactDatatable from '@ashvin27/react-datatable';
import { getPlansListAction, PlanBlockUnBlockAction, PlanDeleteAction} from '../Action/action';
import moment from 'moment';
import Swal from 'sweetalert2';
import Modal from 'react-modal';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CSVLink } from 'react-csv';
import Cookies from 'js-cookie';
import * as XLSX from 'xlsx';
const loginData = (!Cookies.get('loginSuccessBondiAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessBondiAdmin'));

const Plans = () => {
    const [plansList, setplansList] 				= useState({});   
    const [CSVData, setCSVData] 					= useState([]);   
	const [startIndex, setstartIndex] 				= useState(0);		
    const [spinloader, setspinloader] 				= useState(0);   
    
    useEffect(() => {
		setspinloader(1);		
		  if (!loginData?.email) {
            window.location.href = `${config.baseUrl}login`
        }        
       getPlansList();
       
    }, []) 
   const getPlansList = async () => {
        let res = await getPlansListAction();
        if (res.success) {
            setplansList(res.data);
            if (res.data.length > 0) {
                let csvData = res.data;
                let csvAllData = [];
                for (let i = 0; i < csvData.length; i++) {
                    if (i == 0) {
                        csvAllData[i] = ['Plan name', 'Plan Price', 'Plan type', 'Plan days'];
                    }
                    csvAllData[i + 1] = [csvData[i].name, csvData[i].price, csvData[i].type,csvData[i].days];
                }
                setCSVData(csvAllData);
            }
        }
        else
        {
			setplansList({});	
		}
    }
     
   const columns = [
		{
			key: "Sno.",
			text: "#",
			cell: (row, index) => index + 1,
		},
		{
			key: "name",
			text: "Plan name"			
		},
		{
			key: "price",
			text: "Plan Price",
			cell: (item) => {
				return (
					<>
						${item.price}
					</>
				)
			}
		},
		{
			key: "days",
			text: "Plan days",
			cell: (item) => {
				return (
					<>
						{item.days} Days
					</>
				)
			}
		},
		{
			key: "status",
			text: "Action",
			cell: (item) => {
				return (
					<>
						<div className="btn-group mb-5">
						{ item.status === 0 ?
							<>
								<button type="button" className="btn btn-danger btn-sm " onClick={() => PlanBlockUnBlock(item.plan_id, 1)}><i className='fa fa-unlock'></i> Inactive</button>
								&nbsp;&nbsp;&nbsp;
							<Link to={`${config.baseUrl}edit-plan/` + item.plan_id}>
									<button className='btn btn-sm btn-primary'><i className='fa fa-pencil'></i>  Edit</button>
								</Link>
								&nbsp; &nbsp;&nbsp;
								<button type="button" className="btn btn-danger btn-sm " onClick={() => PlanDelete(item.plan_id)}><i className='fa fa-trash'></i> Delete</button>
							</>
							: item.status === 1 ?
							<>
								<button type="button" className="btn btn-primary btn-sm " onClick={() => PlanBlockUnBlock(item.plan_id, 0)}><i className='fa fa-ban'></i> Active</button>
								&nbsp;&nbsp;&nbsp;
								<Link to={`${config.baseUrl}edit-plan/` + item.plan_id}>
									<button className='btn btn-sm btn-primary'><i className='fa fa-pencil'></i>  Edit</button>
								</Link>
								&nbsp;&nbsp;&nbsp;
								<button type="button" className="btn btn-danger btn-sm " onClick={() => PlanDelete(item.plan_id)}><i className='fa fa-trash'></i> Delete</button>
								&nbsp;
							</>
							: '' 
						}
						</div>
					</>
				);
			}
		}
	];

	const configForTable = {
		page_size: 10,
		length_menu: [10, 20, 50],
		show_filter: true,
		show_pagination: true,
		pagination: "advance",
		button: {
		excel: false,
		print: false,
		},
	};
	
	
	const PlanDelete = async (id) => {
		Swal.fire({
			title: 'Are you sure? ',
			text: "You want to Delete this Plan!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
		}).then(async (result) => {
			if (result.isConfirmed) {
				let res = await PlanDeleteAction({'id':id});
				if (res.success) {
					toast.success(res.msg);
					getPlansList();
				} else {
					toast.error(res.msg);
				}
			}
		});
	}

	const PlanBlockUnBlock = async (id, status) => {
		Swal.fire({
			title: 'Are you sure?',
			text: status == 0 ? "You want to deactivate this Plan!”" : "You want to activate this Plan!”",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: status == 0 ? 'Yes, deactivate it!' : 'Yes, activate it!'
		}).then(async (result) => {
			if (result.isConfirmed) {
			let res = await PlanBlockUnBlockAction({ 'id': id, 'status': status });
				if (res.success) {
					getPlansList();
					Swal.fire(
						status == 0 ? 'Deactivate!' : 'Activate',
						res.msg,
						'success'
					)
				} else {
					Swal.fire(
						'Failed!',
						res.msg,
						'error'
					)
				}
			}
		})
	}

	
	
	
    return (

        <>
            <div className="wrapper">
                <Toaster />
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="container-full">
                        {/* Main content */}
                        <div className="content-header">
                            <div className="d-flex align-items-center">
                                <div className="me-auto">
                                    <h3 className="page-title mb-5 pb-2">Plans List</h3>
                                 </div>
                            </div>
                            <hr />
                        </div>
                        <section className="content">
                            <div className="row">
                                <div className="col-lg-12 col-12">
                                    <div className="box">
                                        <div className="box-header with-border">
											<div className="row mb-15">
												<h4 className="box-title col-md-2">Plans ({plansList.length})</h4>
												<div className="col-md-10 text-right">
											   <a href={`${config.baseUrl}add-plan`}
										className="btn btn-sm btn-primary add_btn"><i className='fa fa-plus'></i> Add Plan</a>
												</div>
                                        </div>
                                        
                                        </div>
                                       <div className="box-body">
											<ReactDatatable
												config={configForTable}
												records={plansList}
												columns={columns}
											/>
										</div>
                                    </div>
                                </div>
                            </div>
                        </section>                       
                    </div>
                </div>
                
          		
                <Footer />
            </div>
        </>


    )

}
export default Plans;
