import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import toast, { Toaster } from "react-hot-toast";
import config from "../coreFIles/config";
import Cookies from 'js-cookie'
import { Link, useParams  } from 'react-router-dom';
import { getSpamtextDetailAction, updateSpamTextAction } from "../Action/action";
const loginData = (!Cookies.get('loginSuccessBondiAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessBondiAdmin'));


const Editspam = () => {
	const { id } = useParams();
	const [spinloader, setspinloader] = useState(0);
	const [validatioError, setvalidatioError] = useState({});
	const [spamdata, setspamdata] = useState({});


	useEffect(() => {
		if (!loginData?.email) {
			window.location.href = `${config.baseUrl}login`
		}
		
		getSpamtextDetails();
	}, [])

	const getSpamtextDetails = async () => {
        let res = await getSpamtextDetailAction({id:id});
        if (res.success) {
            setspamdata(res.data);
        }
        else
        {
			setspamdata({});	
		}
    } 

	const inputHandler = async (e) => {
		const { name, value } = e.target;
		setspamdata((old) => {
			return { ...old, [name]: value };
		});
	};

	function validate() {
		let spamtextError = "";
		let isactiveError = "";

		if (spamdata.spamtext === "") {
			spamtextError = "Spam text is required.";
		}
		if (spamdata.isactive === "") {
			isactiveError = "Status is required.";
		}
		if (spamtextError || isactiveError) {
			setvalidatioError({ spamtextError, isactiveError });
			return false;
		} else {
			setvalidatioError({ spamtextError, isactiveError });
			return true;
		}
	}
	const handleKeyPress = (e) => {
		// Allow only numeric values (0-9) and certain control keys
		const isValidInput = /^[0-9\b]+$/.test(e.key);

		if (!isValidInput) {
			e.preventDefault();
		}
	};

	const updateSpamtext = async (e) => {
		e.preventDefault()
		const isValid = validate();
		console.log(isValid);
		if (!isValid) {
		}
		else {
			setspinloader(1);
			let res = await updateSpamTextAction(spamdata);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}spam-list`;
				}, 2000);
			}
			else {
				toast.error(res.msg);
			}
		}
	}

	return (
		<>
			<div className="wrapper">
				<Header />
				<Toaster />
				<Sidebar />
				<div className="content-wrapper">
					<div className="container-full">
						<div className="content-header">
							<div className="d-flex align-items-center">
								<div className="me-auto">
									<h3 className="page-title mb-5 pb-2">Edit Spam Filter Text</h3>
								</div>
							</div>
							<hr />
						</div>
						{/* Content Header (Page header) */}

						{/* Main content */}
						<section className="content">
							<div className="row">
								<div className="col-lg-12 col-12">
									<div className="box">
										<div className="box-header with-border">
											<h4 className="box-title">Edit Spam Filter Text</h4>
											<a href={`${config.baseUrl}spam-list`} className="btn btn-sm btn-primary add_btn">Back</a>
										</div>
										<div className="row mt-20 mb-50 ml-15 mr-15">
											<form onSubmit={updateSpamtext}>
												<div className="col-md-12">
													<div className="row">
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">Enter Spam Text<span className="req-star">*</span></label>
																<div className="col-md-12">
																	<textarea className="form-control" type="text" name="spamtext" rows='4' id='spamtextError' onChange={inputHandler} value={spamdata?.spamtext}></textarea>
																</div>
																<span className="validationErr">{validatioError.spamtextError}</span>
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">Status <span className="req-star">*</span></label>
																<div className="col-md-12">
																	<select name='isactive' className="form-control" id='isactiveError' onChange={inputHandler} value={spamdata?.isactive}>
																		<option value="">Select status</option>
																		<option value="1">Active</option>
																		<option value="0">In-active</option>
																	</select>
																</div>
																<span className="validationErr">{validatioError.isactiveError}</span>
															</div>
														</div>
														<div className="col-md-12">
															<br />
															<div className="text-center pull-left">
																{spinloader == '0' ?
																	<button className='btn btn-primary' >Submit</button>
																	:
																	<button disabled className='btn btn-primary' >Submiting <i className="fa fa-spinner fa-spin validat"></i></button>
																}
															</div>
														</div>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</section>
						{/* /.content */}
					</div>
				</div>

				<Footer />
			</div>
		</>
	);
};
export default Editspam;
