import React, { useEffect, useState } from 'react'
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import Header from '../directives/header';
import Footer from '../directives/footer';
import Sidebar from '../directives/sidebar';
import ReactDatatable from '@ashvin27/react-datatable';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {getAdPaymentlistAction } from '../Action/action'
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
const loginData = (!Cookies.get('loginSuccessBondiAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessBondiAdmin'));

const Adpayments = () => {
	const [adPaymentList, setAdPaymentList]	= useState([]);
	
	useEffect(() => {
		if (!loginData?.email) {
			window.location.href = `${config.baseUrl}login`
		}
		
		getAdPaymentlist();
	}, [])
	
	const getAdPaymentlist = async () => {
		let res = await getAdPaymentlistAction();
		if (res.success) {
			let data = res.data;
			setAdPaymentList(data);
		}
		else
		{
			setAdPaymentList([]);
		}
	}
	
	function planstatus(date,days) {
		var fromdate	= new Date(date);
		var curdate		= new Date();
		var result		= fromdate.setDate(fromdate.getDate() + days);
		var newdate		= new Date(result);
		
		if(Date.parse(curdate) < Date.parse(newdate)){
        	return "Paid";
		}
		else{
			return "Free";
		}
    }
	
	const columns = [
		{
			key: "Sno.",
			text: "#",
			cell: (row, index) => index + 1,
		},
		{
			key: "adid",
			text: "Ad Id"
		},
		{
			key: "ad_name",
			text: "Ad Name",
			cell: (item) => {
				return (
					<>
						<Link to={`${config.baseUrl}edit-ad/${item.adid}`} >
							{item.ad_name}
						</Link>
					</>
				)
			}
		},
		{
			key: "member_name",
			text: "Member Name",
			cell: (item) => {
				return (
					<>
						{ item.member_name != '' ?
							<Link to={`${config.baseUrl}edit-user/${item.member_id}`} >
								{item.member_name}
							</Link>
							: ''
						}
					</>
				)
			}
		},
		{
			key: "amountReceived",
			text: "Amount Paid"
		},
		{
			key: "receivedOn",
			text: "Date",
			cell: (item) => {
				return (
					<>
						{ item.receivedOn != "" ? moment(item.receivedOn).format('DD/MM/YYYY') : '' }
					</>
				)
			}
		},
		{
			key: "currency",
			text: "Currency"
		},
		{
			key: "planDays",
			text: "Current Status",
			cell: (item) => {
				return (
					<>
						{ item.receivedOn != "" ? planstatus(item.receivedOn, item.planDays) : '' }
					</>
				)
			}
		}
	];

	const configForTable = {
		page_size: 10,
		length_menu: [10, 20, 50],
		show_filter: true,
		show_pagination: true,
		pagination: "advance",
		button: {
		excel: false,
		print: false,
		},
	};

	return (
		<>
			<div className="wrapper">
				<Toaster />
				<Header />
				<Sidebar />
				<div className="content-wrapper">
					<div className="container-full">
						{/* Main content */}
						<div className="content-header">
							<div className="d-flex align-items-center">
								<div className="me-auto">
									<h3 className="page-title mb-5 pb-2">Ads Payment History</h3>
								</div>
							</div>
							<hr />
						</div>
						<section className="content">
							<div className="row">
								<div className="col-lg-12 col-12">
									<div className="box">
										<div className="box-header with-border">
											<div className="row mb-15">
												<h4 className="box-title col-md-12">Ads Payment History ({adPaymentList.length})</h4>
												
											</div>
										</div>
										
										<div className="box-body">
											<ReactDatatable
												config={configForTable}
												records={adPaymentList}
												columns={columns}
											/>
										</div>
									</div>
								</div>
							</div>
						</section>                       
					</div>
				</div>      	
				<Footer />
			</div>
		</>
	)
}
export default Adpayments;
