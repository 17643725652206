import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import toast, { Toaster } from "react-hot-toast";
import JoditEditor from "jodit-react";
import config from "../coreFIles/config";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Cookies from 'js-cookie';
import { createAdAction, getParentCatsAction, getChildCatsAction, getSearchSuburbAction, getPremiumPlansAction, getFeaturedPlansAction, getPlanDetailsAction } from "../Action/action";
import avt from '../assets/avata_profile.jpg';
import { Link } from 'react-router-dom';

const loginData = (!Cookies.get('loginSuccessBondiAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessBondiAdmin'));
const Adcreate = () => {
	const editorHeight = '400px';
	const [spinloader, setspinloader] = useState(0);
	const [currentDate, setcurrentDate] = useState(new Date());
	const [validatioError, setvalidatioError] = useState({});
	const [parentcatsList, setparentCats] 	= useState([]);
	const [premiumPlans, setpremiumPlans] 	= useState([]);
	const [featuredPlans, setfeaturedPlans] 	= useState([]);
	const [childcatsList, setchildCats] 	= useState([]);
	const [SuburbsearchResults, setSuburbsearchResults] 	= useState([]);
	const [suburb, setsuburb] 	= useState('');
	const [addata, setaddata] = useState({
		parent_id: '',
		category_id: '',
		title: '',
		description: '',
		suburb: '',
		age_group: '',
		area: '',
		gender: '',
		images: [],
		phone: '',
		yt_video_url: '',
		tags: '',
		hide_my_id: 0,
		status: '',
		plan_id: 0,
		whatson_date: '',
		event_type: '',
		price: '',
		property_type: '',
		bedrooms: '',
		unit_flat: '',
		street_no: '',
		street_name: '',
		work_type: '',
		abn: '',
		accreditation: '',
		from_date: '',
		to_date: '',
		planDays: '',
		planAmount: '',
		member_id: loginData?.id,
	});
	
	useEffect(() => {
		if (!loginData?.email) {
			window.location.href = `${config.baseUrl}login`
		}     
		
		getParentCats();
		getPremiumPlans();
		getFeaturedPlans();
	}, [])
	
	const getParentCats = async () => {
		let res = await getParentCatsAction();
		if (res.success) {
			setparentCats(res.data);
		}
		else{
			setparentCats({});
		}
	}
	
	const getPremiumPlans = async () => {
		let res = await getPremiumPlansAction();
		if (res.success) {
			setpremiumPlans(res.data);
		}
		else{
			setpremiumPlans({});
		}
	}
	
	const getFeaturedPlans = async () => {
		let res = await getFeaturedPlansAction();
		if (res.success) {
			setfeaturedPlans(res.data);
		}
		else{
			setfeaturedPlans({});
		}
	}
	
	const inputHandler = async (e) => {
		const { name, value } = e.target;
		setaddata((old) => {
			return { ...old, [name]: value };
		});
	};  

	const inputfileHandler = (e) => {
		e.preventDefault()
		let files = Array.from(e.target.files);		
	   	setaddata((old) => {
			return { ...old, 'images' : files }
	   	})
	};

	function formatDate(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

	function adddaysToDate(days) {
		var cdate	= new Date();
		var result	= cdate.setDate(cdate.getDate() + days);
		var newdate	= formatDate(result);
		
        return newdate;
    }

	const whatsonDate = e => {
        let whtsonDate = formatDate(e);
		setaddata((old) => {
			return { ...old, 'whatson_date': whtsonDate }
		})
    }
    
	const descriptionHandler = async (e) => {
		setaddata((old) => {
		  return { ...old, 'description': e }
		})
	}
	
	const inputplanHandler = async (e) => {
		const { name, value } = e.target;

		let fromDate	= formatDate(currentDate);
			
		let res = await getPlanDetailsAction({id:value});
		if (res.success) {
			let toDate	= adddaysToDate(res.data.days);
			
			setaddata((old) => {
				return { ...old,
					'from_date': fromDate,
					'to_date': toDate,
					'planDays': res.data.days,
					'planAmount': res.data.price,
					[name]: value 
				};
			});
		}else{
			setaddata((old) => {
				return { ...old,
					'from_date': '',
					'planDays': '',
					'to_date': '',
					'planAmount': '',
					[name]: 0
				};
			});
		}
	};

	const inputwork_typeHandler = async (e) => {
		const { name, value } = e.target;

		if (value == 0) {
			setaddata((old) => {
				return { ...old, [name]: 0 };
			});
		} else {
			setaddata((old) => {
				return { ...old, [name]: value };
			});
		}
	};

	const checkboxHandler = (e) => {
		const { name, value } = e.target;
		if (e.target.checked) {
			setaddata((old) => {
				return { ...old, [name]: value };
			});
		} else {
			setaddata((old) => {
				return { ...old, [name]: 0 };
			});
		} 
	};

	const SearchSuburb = async (str) => {
		try {
			setsuburb(str);
			let res = await getSearchSuburbAction({str:str});
			setSuburbsearchResults(res.data);
		}
		catch (error) {
			console.error(error);
		}
	};

	const handleSelectSuburb = (row) => {
		setsuburb(row);
		setaddata((old) => {
			return { ...old, 'suburb': row };
		});
		setSuburbsearchResults({});
	};
	
	const handleParentCat = async(id) => {
		if(id==1){
			setaddata((old) => {
				return { ...old,
					'parent_id': id,
					'property_type': '',
					'bedrooms': '',
					'unit_flat': '',
					'street_no': '',
					'street_name': '',
					'price': '',
					'whatson_date': '',
					'event_type': '',
					'work_type': '',
					'abn': '',
					'accreditation': '',
				};
			});
		}
		else if(id==2){
			setaddata((old) => {
				return { ...old,
					'parent_id': id,
					'age_group': '',
					'gender': '',
					'price': '',
					'whatson_date': '',
					'event_type': '',
					'work_type': '',
					'abn': '',
					'accreditation': '',
				};
			});
		}
		else if(id==3){
			setaddata((old) => {
				return { ...old,
					'parent_id': id,
					'age_group': '',
					'gender': '',
					'property_type': '',
					'bedrooms': '',
					'unit_flat': '',
					'street_no': '',
					'street_name': '',
					'whatson_date': '',
					'event_type': '',
					'work_type': '',
					'abn': '',
					'accreditation': '',
				};
			});
		}
		else if(id==4){
			setaddata((old) => {
				return { ...old,
					'parent_id': id,
					'age_group': '',
					'gender': '',
					'property_type': '',
					'bedrooms': '',
					'unit_flat': '',
					'street_no': '',
					'street_name': '',
					'price': '',
					'work_type': '',
					'abn': '',
					'accreditation': '',
				};
			});
		}
		else if(id==5){
			setaddata((old) => {
				return { ...old,
					'parent_id': id,
					'age_group': '',
					'gender': '',
					'property_type': '',
					'bedrooms': '',
					'unit_flat': '',
					'street_no': '',
					'street_name': '',
					'price': '',
					'whatson_date': '',
					'event_type': '',
					'abn': '',
					'accreditation': '',
				};
			});
		}
		else if(id==6){
			setaddata((old) => {
				return { ...old,
					'parent_id': id,
					'age_group': '',
					'gender': '',
					'property_type': '',
					'bedrooms': '',
					'unit_flat': '',
					'street_no': '',
					'street_name': '',
					'price': '',
					'whatson_date': '',
					'event_type': '',
					'work_type': '',
					'abn': '',
					'accreditation': '',
				};
			});
		}
		else if(id==7){
			setaddata((old) => {
				return { ...old,
					'parent_id': id,
					'age_group': '',
					'gender': '',
					'property_type': '',
					'bedrooms': '',
					'unit_flat': '',
					'street_no': '',
					'street_name': '',
					'price': '',
					'whatson_date': '',
					'event_type': '',
					'work_type': '',
				};
			});
		}
		
		let res = await getChildCatsAction({id:id});
		if (res.success) {
			setchildCats(res.data);
		}
		else{
			setchildCats({});
		}
		
	};
	
	function validate() {	 		
		let parentError = "";
		let categoryError = "";
		let titleError = "";
		let description = "";
		let suburbError = "";
		let areaError = "";
		let statusError = "";
		
		if (addata.parent_id === "") {
			parentError = "Category is required.";
		}
		
		if (addata.category_id === "") {
			categoryError = "Sub Category is required.";
		}
		
		if (addata.title === "") {
			titleError = "Ad title is required.";
		}
		
		if (addata.description === "") {
			description = "Description is required.";
		}
		
		if (addata.status === "") {
			statusError = "Status is required.";
		}
		
		if (addata.suburb === "") {
			suburbError = "Suburb is required.";
		}
		
		if (addata.area === "") {
			areaError = "Area is required.";
		}
		
		if (titleError || parentError || categoryError || description || statusError || suburbError || areaError) {
			setvalidatioError({
				titleError,parentError,categoryError,description,statusError,suburbError,areaError });
			return false;
		}
		else {
			setvalidatioError({
			titleError,parentError,categoryError,description,statusError,suburbError,areaError });
			return true;
		}
	}
	
	const handleKeyPress = (e) => {
		// Allow only numeric values (0-9) and certain control keys
		const isValidInput = /^[0-9\b]+$/.test(e.key);
		
		if (!isValidInput) {
			e.preventDefault();
		}
	};
	
	const createAd = async (e) => {
		e.preventDefault()
		const isValid = validate();
		
		if (!isValid) {		
		}
		else {
			setspinloader(1);				                             
			let res = await createAdAction(addata);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}ad-list`;
				}, 2000);
			}			 
			else {
				setspinloader(0);
				toast.error(res.msg);
			}
		} 
	}
	
	return (
		<>
			<div className="wrapper">
				<Header />
				<Toaster />
				<Sidebar />
				<div className="content-wrapper">
					<div className="container-full">
						<div className="content-header">
							<div className="d-flex align-items-center">
								<div className="me-auto">
									<h3 className="page-title mb-5 pb-2">Create Ad</h3>
								</div>
							</div>
							<hr />
						</div>
						{/* Content Header (Page header) */}

						{/* Main content */}
						<section className="content">
							<div className="row">
								<div className="col-lg-12 col-12">
									<div className="box">
										<div className="box-header with-border">
											<h4 className="box-title">Create Ad</h4>
											<a href={`${config.baseUrl}ad-list`} className="btn btn-sm btn-primary add_btn">Back</a> 
										</div>
										<div className="row mt-20 mb-50 ml-15 mr-15">
											<form onSubmit={createAd}>
												<div className="col-md-12">
													<div className="row">
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																	Category <span className="req-star">*</span>
																</label>
																<div className="col-md-12">
																	<select name='parent_id' className="form-control" id='parentError' onChange={(e) => handleParentCat(e.target.value)}>
																		<option value="">Select Category</option>  
																		{ parentcatsList.length >0 ?
																			parentcatsList.map((item)=>{
																				return(
																					<option value={item.cat_id}>{item.cat_name}</option>
																				)
																			})
																			:''
																		}
																	</select>
																</div>
																<span className="validationErr">
																	{validatioError.parentError}
																</span>
															</div>
														</div>
														
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																	Sub Category <span className="req-star">*</span>
																</label>
																<div className="col-md-12">
																	<select name='category_id' className="form-control" id='categoryError' onChange={inputwork_typeHandler}>
																		<option value="">Select Subcategory</option>  
																		{ childcatsList.length >0 ?
																			childcatsList.map((item)=>{
																				return(
																					<option value={item.cat_id}>{item.cat_name}</option>
																				)
																			})
																			:''
																		}
																	</select>
																</div>
																<span className="validationErr">
																	{validatioError.categoryError}
																</span>
															</div>
														</div>
														
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																	Title <span className="req-star">*</span>
																</label>
																<div className="col-md-12">
																	<input className="form-control" type="text" name="title" id='titleError' onChange={inputHandler} placeholder="Enter Title" />
																</div>
																<span className="validationErr">
																	{validatioError.titleError}
																</span>
															</div>
														</div>
														<div className="col-md-12">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">Description</label>
																<div className="col-md-12">
																	<JoditEditor
																		onChange={descriptionHandler}
																		value=''
																		name="description"
																		id="description"
																		style={{ height: editorHeight }}
																		className="form-control"
																		placeholder="Enter description"
																		/>						
																</div>
																<span className="validationErr">
																	{validatioError.description}
																</span>
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																	Suburb<span className="req-star">*</span>                    
																</label>
																<div className="col-md-12">
																	<input type="text" name="suburb" className="form-control" id="suburb"  onChange={(e) => SearchSuburb(e.target.value)} autocomplete="off" value={suburb} />
																</div>
																<span className="validationErr">
																	{validatioError.suburbError}
																</span>
																{SuburbsearchResults.length > 0 ? (
																	<ul className="suburbList">
																		{SuburbsearchResults.map((row) => (
																			<li key={row.id} onClick={() => handleSelectSuburb(row.rowlocation)}>
																				{row.rowlocation} 
																			</li>
																		))}
																	</ul>
																) : null}
															</div>
														</div>
														
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																	Area <span className="req-star">*</span>
																</label>
																<div className="col-md-12">
																	<select name='area' className="form-control" id='areaError' onChange={inputwork_typeHandler}>
																		<option value="">Select</option>  
																		<option value="Australia Wide">Australia Wide</option>
																		<option value="NSW - Sydney">NSW - Sydney</option>
																		<option value="NSW - Regional">NSW - Regional</option>
																		<option value="QLD - Brisbane">QLD - Brisbane</option>
																		<option value="QLD - Regional">QLD - Regional</option>
																		<option value="VIC - Melbourne">VIC - Melbourne</option>
																		<option value="VIC - Regional">VIC - Regional</option>
																		<option value="WA - Perth">WA - Perth</option>
																		<option value="WA - Regional">WA - Regional</option>
																		<option value="SA - Adelaide">SA - Adelaide</option>
																		<option value="SA - Regional">SA - Regional</option>
																		<option value="NT - Darwin">NT - Darwin</option>
																		<option value="NT - Regional">NT - Regional</option>
																		<option value="TAS - Hobart">TAS - Hobart</option>
																		<option value="TAS - Regional">TAS - Regional</option>
																	</select>
																</div>
																<span className="validationErr">
																	{validatioError.areaError}
																</span>
															</div>
														</div>                                              
														
														{ addata?.parent_id==='1'?
														<>
															<div className="col-md-6">
																<div className="form-group row mb-1">
																	<label className="col-form-label col-md-12">
																		Age Group
																	</label>
																	<div className="col-md-12">
																		<select name='age_group' className="form-control" id='age_groupError' onChange={inputwork_typeHandler}>
																			<option value="">Select</option>
																			<option value="18-22">18-22</option>
																			<option value="23-26">23-26</option>
																			<option value="27-30">27-30</option>
																			<option value="31-36">31-36</option>
																			<option value="37-42">37-42</option>
																			<option value="43-50">43-50</option>
																			<option value="50-60">50-60</option>
																			<option value="over 61">over 61</option>
																		</select>
																	</div>
																</div>
															</div>
															<div className="col-md-6">
																<div className="form-group row mb-1">
																	<label className="col-form-label col-md-12">
																		Gender
																	</label>
																	<div className="col-md-12">
																		<select name='gender' className="form-control" id='genderError' onChange={inputwork_typeHandler}>
																			<option value="">Select</option>
																			<option value="0">Male</option>
            																<option value="1">Female</option>
																		</select>
																	</div>
																</div>
															</div>
														</>
														: ''}
														{addata?.parent_id==='2'?
														<>
															<div className="col-md-6">
																<div className="form-group row mb-1">
																	<label className="col-form-label col-md-12">
																		Property Type
																	</label>
																	<div className="col-md-12">
																		<select name='property_type' className="form-control" id='property_typeError' onChange={inputwork_typeHandler}>
																			<option value="">Select</option>
																			<option value="All">All</option>
																			<option value="Studio">Studio</option>
																			<option value="Apartment">Apartment</option>
																			<option selected="selected" value="House">House</option>
																			<option value="Rural">Rural</option>
																		</select>
																	</div>
																</div>
															</div>
															<div className="col-md-6">
																<div className="form-group row mb-1">
																	<label className="col-form-label col-md-12">
																		Bedrooms
																	</label>
																	<div className="col-md-12">
																		<select name='bedrooms' className="form-control" id='bedroomsError' onChange={inputwork_typeHandler}>
																			<option value="">Select</option>
																			<option value="All">All</option>
																			<option value="1 or more">1 or more</option>
																			<option value="2 or more">2 or more</option>
																			<option value="3 or more">3 or more</option>
																			<option value="4 or more">4 or more</option>
																		</select>
																	</div>
																</div>
															</div>
															<div className="col-md-6">
																<div className="form-group row mb-1">
																	<label className="col-form-label col-md-12">
																		Unit/Flat
																	</label>
																	<div className="col-md-12">
																		<input type="text" name='unit_flat' className="form-control" id='unit_flatError'  onChange={inputHandler} />
																	</div>
																</div>
															</div>
															<div className="col-md-6">
																<div className="form-group row mb-1">
																	<label className="col-form-label col-md-12">
																		Street No
																	</label>
																	<div className="col-md-12">
																		<input type="text" name='street_no' className="form-control" id='street_noError'  onChange={inputHandler} />
																	</div>
																</div>
															</div>
															<div className="col-md-6">
																<div className="form-group row mb-1">
																	<label className="col-form-label col-md-12">
																		Street Name
																	</label>
																	<div className="col-md-12">
																		<input type="text" name='street_name' className="form-control" id='street_nameError'  onChange={inputHandler} />
																	</div>
																</div>
															</div>
															<div className="col-md-6">
																<div className="form-group row mb-1">
																	<label className="col-form-label col-md-12">
																		Price
																	</label>
																	<div className="col-md-12">
																		<input type="text" name='price' className="form-control" id='priceError'  onChange={inputHandler} onKeyPress={handleKeyPress} />
																	</div>
																</div>
															</div>
														</>
														: ''}
														{addata?.parent_id==='3'?
														<>
															<div className="col-md-6">
																<div className="form-group row mb-1">
																	<label className="col-form-label col-md-12">
																		Price
																	</label>
																	<div className="col-md-12">
																		<input type="text" name='price' className="form-control" id='priceError'  onChange={inputHandler} onKeyPress={handleKeyPress} />
																	</div>
																</div>
															</div>
														</>
														: ''}
														{addata?.parent_id==='4'?
														<>
															<div className="col-md-6">
																<div className="form-group row mb-1">
																	<label className="col-form-label col-md-12">
																		When is the Event?
																	</label>
																	<div className="col-md-12">
																		<DatePicker onChange={whatsonDate}  placeholder ="Select Date" autoComplete="off" name="whatson_date" id="whatson_dateError" className='form-control' value={addata.whatson_date} minDate={currentDate}  />
																	</div>
																</div>
															</div>
															<div className="col-md-6">
																<div className="form-group row mb-1">
																	<label className="col-form-label col-md-12">
																		Day/Night Event?
																	</label>
																	<div className="col-md-12">
																		<select name='event_type' className="form-control" id='event_typeError' onChange={inputwork_typeHandler}>
																			<option value="">Select</option>
																			<option value="Day">Day</option>
																			<option value="Night">Night</option>
																		</select>
																	</div>
																</div>
															</div>
														</>
														: ''}
														{addata?.parent_id==='5'?
														<>
															<div className="col-md-6">
																<div className="form-group row mb-1">
																	<label className="col-form-label col-md-12">
																		Work Type
																	</label>
																	<div className="col-md-12">
																		<select name='work_type' className="form-control" id='work_typeError'  onChange={inputwork_typeHandler}>
																			<option value="">Select Work Type</option>
																			<option value="All">All</option>
																			<option value="Full Time">Full Time</option>
																			<option value="Part Time">Part Time</option>
																			<option value="Contract/Temp">Contract/Temp</option>
																			<option value="Freelance">Freelance</option>
																		</select>
																	</div>
																</div>
															</div>
														</>
														: ''}
														{addata?.parent_id==='7'?
														<>
															<div className="col-md-6">
																<div className="form-group row mb-1">
																	<label className="col-form-label col-md-12">
																		ABN (Australian Business Number)
																	</label>
																	<div className="col-md-12">
																		<input className="form-control" type="text" name="abn" id='abnError' onChange={inputHandler} placeholder="Enter Australian Business Number" onKeyPress={handleKeyPress} />
																	</div>
																</div>
															</div>
															<div className="col-md-6">
																<div className="form-group row mb-1">
																	<label className="col-form-label col-md-12">
																		Accreditation
																	</label>
																	<div className="col-md-12">
																		<input className="form-control" type="text" name="accreditation" id='accreditationError' onChange={inputHandler} placeholder="Enter Accreditation"  />
																	</div>
																</div>
															</div>
														</>
														:''
														}
														
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																	Images
																</label>
																<div className="col-md-12">
																	<input className="form-control" type="file" name="images" id='imagesError' onChange={inputfileHandler} multiple />
																</div>
															</div>
														</div>
														
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																	Phone Number 
																</label>
																<div className="col-md-12">
																	<input className="form-control" type="text" name="phone" id='phoneError' onChange={inputHandler} placeholder="Enter Phone Number" onKeyPress={handleKeyPress} />
																</div>
															</div>
														</div>
														
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																	Youtube Video URL 
																</label>
																<div className="col-md-12">
																	<input className="form-control" type="text" name="yt_video_url" id='yturl_Error' onChange={inputHandler} placeholder="Enter youtube video url" />
																</div>
															</div>
														</div>
														
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																	Tags 
																</label>
																<div className="col-md-12">
																	<input className="form-control" type="text" name="tags" id='tags_Error' onChange={inputHandler} placeholder="Enter ad tags" />
																</div>
															</div>
														</div>
														
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																	Hide My ID 
																</label>
																<div className="col-md-12">
																	<input className="" type="checkbox" name="hide_my_id" id='hdmyid_Error' onChange={checkboxHandler} value="1" />
																</div>
															</div>
														</div>
														
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																Status <span className="req-star">*</span>
																</label>
																<div className="col-md-12">
																	<select name='status' className="form-control" id='statusError' onChange={inputwork_typeHandler}>
																		<option value="">-- Please select --</option>
																		<option value="0">Inactive</option>
																		<option value="1">Active</option>
																	</select>	
																</div>
																<span className="validationErr">
																	{validatioError.statusError}
																</span>
															</div>
														</div>
														
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																Plan 
																</label>
																<div className="col-md-12">
																	<select name='plan_id' className="form-control" id='plan_idError' onChange={inputplanHandler} value={addata?.plan_id}>
																		<option value="0">Basic Plan (Free)</option>
																		
																		{ premiumPlans.length >0 ?
																			premiumPlans.map((item)=>{
																				return(
																					<option value={item.plan_id}>{item.name+" ($"+ item.price +")"}</option>
																				)
																			})
																			:''
																		}
																		{ featuredPlans.length >0 ?
																			featuredPlans.map((item)=>{
																				return(
																					<option value={item.plan_id}>{item.name +" ($"+ item.price +")"}</option>
																				)
																			})
																			:''
																		}
																	</select>	
																</div>
															</div>
														</div>
														
														
														<div className="col-md-12">
															<div className="text-center pull-left mt-3">
																{spinloader == '0' ?
																	<button className='btn btn-primary' >Submit</button>
																	:
																	<button disabled className='btn btn-primary' >Submiting <i className="fa fa-spinner fa-spin validat"></i></button>
																}
															</div>
														</div>
													</div>                                                   
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</section>
						{/* /.content */}
					</div>
				</div>
				<Footer />
			</div>
		</>
	);
};
export default Adcreate;
