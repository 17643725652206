import React, { useEffect, useState } from 'react'
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import Header from '../directives/header';
import Footer from '../directives/footer';
import Sidebar from '../directives/sidebar';
import ReactDatatable from '@ashvin27/react-datatable';
import { Link } from 'react-router-dom';
import {getBannerslistAction, BannerDeleteAction, BannerBlockUnBlockAction } from '../Action/action'
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
const loginData = (!Cookies.get('loginSuccessBondiAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessBondiAdmin'));

const Users = () => {
    const [BannersList, setBannersList] 			= useState([]);
	const [page, setPage] 							= useState(1); 
	const [startIndex, setstartIndex] 				= useState(0);	
	
    
    useEffect(() => {
		  if (!loginData?.email) {
            window.location.href = `${config.baseUrl}login`
        } 
        getBannerslist(); 
       
    }, [])
    
  const getBannerslist = async () => {
		let res = await getBannerslistAction();
		if (res.success) {
			let data = res.data;			
			setBannersList(data);	
		}
		else
		{
			setBannersList([]);	
		}
	}
      
  const BannerDelete = async (ad_id) => {
		Swal.fire({
			title: 'Are you sure? ',
			text: "You want to Delete this Banner!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
		}).then(async (result) => {
			if (result.isConfirmed) {
				let res = await BannerDeleteAction({'ad_id':ad_id});
				if (res.success) {
					toast.success(res.msg);
					setBannersList();
				} else {
					toast.error(res.msg);
				}
			}
		});
	}
	
  const BannerBlockUnBlock = async (id, is_active) => {
        Swal.fire({
            title: 'Are you sure?',
            text: is_active == 0 ? "You want to deactivate this banner!”" : "You want to activate this banner!”",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: is_active == 0 ? 'Yes, deactivate it!' : 'Yes, activate it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
               let res = await BannerBlockUnBlockAction({ 'id': id, 'is_active': is_active });
                if (res.success) {
                     getBannerslist();
                    Swal.fire(
                        is_active == 0 ? 'Deactivate!' : 'Activate',
                        res.msg,
                        'success'
                    )
                } else {
                    Swal.fire(
                        'Failed!',
                        res.msg,
                        'error'
                    )
                }
            }
        })
    }  
  
   const columns = [
    {
      key: "Sno.",
      text: "#",
      cell: (row, index) => index + 1,
    },
    {
      key: "ad_title",
      text: "Ad Title"      
    },

    {
      key: "ad_url",
      text: "Link To"     
    },
    {
      key: "ad_type",
      text: "Ad Type"     
    },
    {
	key: "status",
	text: "Action",
	cell: (item) => {
		return (
			
			<>
				<div className="btn-group mb-5">
				{ item.status === 0 ?
					   <> <button type="button" className="btn btn-danger btn-sm " onClick={() => BannerBlockUnBlock(item.ad_id, 1)}><i className='fa fa-unlock'></i> Inactive</button>
						&nbsp;&nbsp;&nbsp; <Link to={`${config.baseUrl}edit-banner/` + item.ad_id}>
							<button className='btn btn-sm btn-primary'><i className='fa fa-pencil'></i>  Edit</button>
						</Link> &nbsp; 
				   &nbsp;&nbsp; <button type="button" className="btn btn-danger btn-sm " onClick={() => BannerDelete(item.ad_id)}><i className='fa fa-trash'></i> Delete</button>				   
				   </>				
				: item.status === 1 ?
				   <> <button type="button" className="btn btn-primary btn-sm " onClick={() => BannerBlockUnBlock(item.ad_id, 0)}><i className='fa fa-ban'></i> Active</button>
					&nbsp;&nbsp;&nbsp; <Link to={`${config.baseUrl}edit-banner/` + item.ad_id}>
					<button className='btn btn-sm btn-primary'><i className='fa fa-pencil'></i>  Edit</button>
				</Link> &nbsp; 
				   &nbsp;&nbsp; <button type="button" className="btn btn-danger btn-sm " onClick={() => BannerDelete(item.ad_id)}><i className='fa fa-trash'></i> Delete</button>&nbsp; 				  
				   </>				
					:
					'' 
				}
				</div>
			</>
		);
	}
    }
  ];

  const configForTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    pagination: "advance",
    button: {
      excel: false,
      print: false,
    },
  };
 
    return (

        <>
            <div className="wrapper">
                <Toaster />
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="container-full">
                        {/* Main content */}
                        <div className="content-header">
                            <div className="d-flex align-items-center">
                                <div className="me-auto">
                                    <h3 className="page-title mb-5 pb-2">Banners List</h3>
                                 </div>
                            </div>
                            <hr />
                        </div>
                        <section className="content">
                            <div className="row">
                                <div className="col-lg-12 col-12">
                                    <div className="box">
                                        <div className="box-header with-border">
											<div className="row mb-15">
											<h4 className="box-title col-md-2">Banners ({BannersList.length})</h4>
											<div className="col-md-10 text-right">
											   <a href={`${config.baseUrl}add-banner`} className="btn btn-sm btn-primary add_btn">
												   <i className='fa fa-plus'></i> Add Banners</a>
											</div>
                                        </div>
                                        
                                        </div>
                                        <div className="box-body">
                                          <ReactDatatable
                                                config={configForTable}
                                                records={BannersList}
                                                columns={columns}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>                       
                    </div>
                </div>      	
                <Footer />
            </div>
        </>


    )

}
export default Users;
