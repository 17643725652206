import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import toast, { Toaster } from "react-hot-toast";
import JoditEditor from "jodit-react";
import config from "../coreFIles/config";
import Cookies from 'js-cookie'
import { Link, useParams  } from 'react-router-dom';
import { getCategoryDetailAction, getCategoriesListAction,updateCategoryAction } from "../Action/action";
const loginData = (!Cookies.get('loginSuccessBondiAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessBondiAdmin'));


const Editcategory = () => {
	
	const { id } = useParams();
	const editorHeight = '400px'; 
	const [spinloader, setspinloader] = useState(0);  
	const [validatioError, setvalidatioError] = useState({});	
	const [catList, setcatList] = useState({}); 
	const [catdata, setcatdata] = useState({});
   
  
   useEffect(() => {
	    if (!loginData?.email) {
            window.location.href = `${config.baseUrl}login`
        }
         getCatList(); 
         getCatDetails();
              
    }, [])     
   const getCatDetails = async () => {
        let res = await getCategoryDetailAction({id:id});
        if (res.success) {
            setcatdata(res.data);
        }
        else
        {
			setcatdata({});	
		}
    }  
  const getCatList = async () => {
        let res = await getCategoriesListAction();
        if (res.success) {
            setcatList(res.data);           
        }
        else
        {
			setcatList({});	
		}
    }
   
  const inputHandler = async (e) => {
    const { name, value } = e.target;
    setcatdata((old) => {
      return { ...old, [name]: value };
    });
  };
  
  const descriptionHandler = async (e) => {
		setcatdata((old) => {
		  return { ...old, 'cat_desc': e }
		})
	  }
  
  const meta_descHandler = async (e) => {
		setcatdata((old) => {
		  return { ...old, 'meta_desc': e }
		})
	  }
  
  const meta_keywordsHandler = async (e) => {
		setcatdata((old) => {
		  return { ...old, 'meta_keywords': e }
		})
	  }
  
  function validate() {	 
	let cat_nameError 	= "";	
    let cat_flagError 	= "";      
       
    if (catdata.cat_name === "") {
		cat_nameError = "Name is required.";
    }         
    if (catdata.cat_flag === "") {
		cat_flagError = "Status is required.";
    } 
    if (cat_nameError || cat_flagError ) {
		setvalidatioError({ cat_nameError, cat_flagError });
		return false;
    } else {
		setvalidatioError({ cat_nameError, cat_flagError });
        return true;
    }
  }
  const handleKeyPress = (e) => {
		// Allow only numeric values (0-9) and certain control keys
		const isValidInput = /^[0-9\b]+$/.test(e.key);
		if (!isValidInput) {
		  e.preventDefault();
		}
	  };  
 
  const updateCategory = async (e) => {
        e.preventDefault()
        const isValid = validate();
        console.log(isValid);
        if (!isValid) {		
        }
        else {
            setspinloader(1);           
			let res = await updateCategoryAction(catdata);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}categories`;
				}, 2000);
			}			 
			else {
				toast.error(res.msg);
			}
		} 
	}
  
  return (
        <>
            <div className="wrapper">
                <Header />
                <Toaster />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="container-full">
                        <div className="content-header">
                            <div className="d-flex align-items-center">
                                <div className="me-auto">
                                    <h3 className="page-title mb-5 pb-2">Edit Category</h3>
                                </div>
                            </div>
                            <hr />
                        </div>
                        {/* Content Header (Page header) */}

                        {/* Main content */}
<section className="content">
	<div className="row">
		<div className="col-lg-12 col-12">
			<div className="box">
				<div className="box-header with-border">
					<h4 className="box-title">Edit Category</h4>
					<a href={`${config.baseUrl}categories`} className="btn btn-sm btn-primary add_btn">Back</a> 
				</div>
<div className="row mt-20 mb-50 ml-15 mr-15">
<form onSubmit={updateCategory}>
	<div className="col-md-12">
		<div className="row">                                               
			<div className="col-md-5">
				<div className="form-group row mb-1">
					<label className="col-form-label col-md-12">Category name <span className="req-star">*</span></label>
					<div className="col-md-12">
						<input className="form-control" type="text" name="cat_name" id='cat_nameError' onChange={inputHandler} placeholder="Enter category name" value={catdata?.cat_name}/>
					</div>
					<span className="validationErr">{validatioError.cat_nameError}</span>
				</div>
			</div>
			<div className="col-md-3">
			  <div className="form-group row mb-1">
				<label className="col-form-label col-md-12">
					Parent Category 
				</label>
				<div className="col-md-12">
					<select name='parent_id' className="form-control" id='parent_idError' onChange={inputHandler} value={catdata?.parent_id}>
						<option value="">Select Parent Category</option>
						 {catList.length> 0 ?
							 catList.map(cat => (
							<option value={cat.cat_id}>{cat.cat_name}</option>
						)) : ''}		
					</select>
				</div>
			</div>
			</div>
			<div className="col-md-2">
			  <div className="form-group row mb-1">
				<label className="col-form-label col-md-12">
					Is Adult 
				</label>
				<div className="col-md-12">
					<select name='adult' className="form-control" id='adultError' onChange={inputHandler} value={catdata?.adult}>
						<option value="">Select</option>
						<option value="1">Yes</option>
						<option value="0">No</option>
					</select>
				</div>
			</div>
			</div>			
			<div className="col-md-2">
				<div className="form-group row mb-1">
					<label className="col-form-label col-md-12">Status <span className="req-star">*</span></label>
					<div className="col-md-12">
						<select name='cat_flag' className="form-control" id='cat_flagError' onChange={inputHandler}  value={catdata?.cat_flag}>
						<option value="">Select status</option>
						<option value="1">Active</option>
						<option value="0">In-active</option>
						</select>
					</div>
					<span className="validationErr">{validatioError.cat_flagError}</span>
				</div>
			</div>			
			<div className="col-md-12">
				<div className="form-group row mb-1">
					<label className="col-form-label col-md-12">Description</label>
					<div className="col-md-12">
						<JoditEditor
                              onChange={descriptionHandler}
                              value={catdata?.cat_desc}
                              name="description"
                              id="description"
                              style={{ height: editorHeight }}
                              className="form-control"
                              placeholder="Enter description"
                            />						
					</div>					
				</div>
			</div>
			<div className="col-md-12">
				<div className="form-group row mb-1">
					<label className="col-form-label col-md-12">Meta title </label>
					<div className="col-md-12">
						<input className="form-control" type="text" name="meta_title" id='meta_titleError' onChange={inputHandler} placeholder="Enter meta title"  value={catdata?.meta_title}/>
					</div>					
				</div>
			</div>
			<div className="col-md-12">
				<div className="form-group row mb-1">
					<label className="col-form-label col-md-12">Meta Description</label>
					<div className="col-md-12">
						<JoditEditor
                              onChange={meta_descHandler}
                              value={catdata?.meta_desc}
                              name="meta_desc"
                              id="meta_desc"
                              style={{ height: editorHeight }}
                              className="form-control"
                              placeholder="Enter meta desc"
                            />						
					</div>					
				</div>
			</div>
			<div className="col-md-12">
				<div className="form-group row mb-1">
					<label className="col-form-label col-md-12">Meta keywords</label>
					<div className="col-md-12">
						<JoditEditor
                              onChange={meta_keywordsHandler}
                               value={catdata?.meta_keywords}
                              name="meta_keywords"
                              id="meta_keywords"
                              style={{ height: editorHeight }}
                              className="form-control"
                              placeholder="Enter meta keywords"
                            />						
					</div>					
				</div>
			</div>
			<div className="col-md-12">
				<br />
				<div className="text-center pull-left">
					{spinloader == '0' ?
					<button className='btn btn-primary' >Submit</button>
					:
					<button disabled className='btn btn-primary' >Submiting <i className="fa fa-spinner fa-spin validat"></i></button>
					}
				</div>
			</div>
			</div>                                                   
			</div>
</form>
</div>
</div>
</div>
</div>
</section>
{/* /.content */}
</div>
</div>

                <Footer />
            </div>
        </>
    );
};
export default Editcategory;
