/* eslint-disable eqeqeq */
import React, { useState } from "react";
import config from "../coreFIles/config";
import Cookies from "js-cookie";
const loginData = (!Cookies.get('loginSuccessBondiAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessBondiAdmin'));
const Sidebar = () => {
	const [pageUrl, setPageUrl] = useState(window.location.href);

	const logout = async () => {
		Cookies.remove("loginSuccessBondiAdmin");
		window.location.href = config.baseUrl;
	};
	return (
		<>
		<aside className="main-sidebar">
			{/* sidebar*/}
			<section className="sidebar position-relative">
				<div className="multinav">
					<div className="multinav-scroll" style={{ height: "100%" }}>
						{/* sidebar menu*/}
						<ul className="sidebar-menu" data-widget="tree">
							<li className={pageUrl.match("/dashboard") ? "active" : ""}>
								<a href={`${config.baseUrl}dashboard`}>
									<i className="fa fa-dashboard" />
									<span>Dashboard </span>
								</a>
							</li>  
							<li className={pageUrl.match("setting") ? "active" : ""}>
								<a href={`${config.baseUrl}settings`}>
									<i className="fa fa-cog" />
									<span>Globel Settings</span>
								</a>
							</li>
							<li className={pageUrl.match("plan") ? "active" : ""}>
								<a href={`${config.baseUrl}plans`}>
									<i className="fa fa-money" />
									<span>Plans</span>
								</a>
							</li> 
							
							<li className={pageUrl.match("cat") ? "active" : ""}>
								<a href={`${config.baseUrl}categories`}>
									<i className="fa fa-th" />
									<span>Categories</span>
								</a>
							</li>     
							<li className={pageUrl.match("user") ? "active" : ""}>
								<a href={`${config.baseUrl}users`}>
									<i className="fa fa-users" />
									<span>Users</span>
								</a>
							</li>    
							<li className={pageUrl.match("ad-") ? "active" : ""}>
								<a href={`${config.baseUrl}ad-list`}>
									<i className="fa fa-bullhorn" />
									<span>Ads</span>
								</a>
							</li> 
							<li className={pageUrl.match("banner") ? "active" : ""}>
								<a href={`${config.baseUrl}banners`}>
									<i className="fa fa-picture-o" />
									<span>Banners</span>
								</a>
							</li>
							<li className={pageUrl.match("baner") ? "active" : ""}>
								<a href={`${config.baseUrl}horizontal-baner-list`}>
									<i className="fa fa-picture-o" />
									<span>Horizontal Banner Ads</span>
								</a>
							</li>
							<li className={pageUrl.match("slider") ? "active" : ""}>
								<a href={`${config.baseUrl}sliders`}>
									<i className="fa fa-picture-o" />
									<span>Homepage Sliders</span>
								</a>
							</li>
							<li className={pageUrl.match("members-payment") ? "active" : ""}>
								<a href={`${config.baseUrl}members-payment-history`}>
									<i className="fa fa-exchange" />
									<span>VIP Membership Payments</span>
								</a>
							</li>
							<li className={pageUrl.match("ads-payment") ? "active" : ""}>
								<a href={`${config.baseUrl}ads-payment-history`}>
									<i className="fa fa-exchange" />
									<span>Ads Payment History</span>
								</a>
							</li>
							<li className={pageUrl.match("spam") ? "active" : ""}>
								<a href={`${config.baseUrl}spam-list`}>
									<i className="fa fa-exclamation-triangle" />
									<span>Spam Filter List</span>
								</a>
							</li>
							
							<li className={pageUrl.match("content") ? "active" : ""}>
								<a href={`${config.baseUrl}content-pages`}>
									<i className="fa fa-file-o" />
									<span>Content Pages</span>
								</a>
							</li>
							<li className={pageUrl.match("email") ? "active" : ""}>
								<a href={`${config.baseUrl}emaillisting`}>
									<i className="fa fa-envelope-o" />
									<span>Email Templates</span>
								</a>
							</li>
							
							
							
							
							
							
							
							
							<li className={pageUrl.match("/changepassword") ? "active" : ""}>
								<a href={`${config.baseUrl}changepassword`}>
									<i className="fa fa-lock" />
									<span>Change Password</span>
								</a>
							</li>                
							<li className="">
								<a href="javascript:;" onClick={logout}>
									<i className="fa fa-sign-out" />
									<span>Logout</span>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</section>
		</aside>
		</>
	);
};
export default Sidebar;
