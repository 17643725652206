import React, { useEffect, useState } from 'react'
import config from '../coreFIles/config';
import Header from '../directives/header';
import Footer from '../directives/footer';
import Sidebar from '../directives/sidebar';
import { Link } from 'react-router-dom';
import ReactDatatable from '@ashvin27/react-datatable';
import { getCategoriesListAction, CategoryBlockUnBlockAction, CategoryDeleteAction} from '../Action/action';
import Swal from 'sweetalert2';
import { CSVLink } from 'react-csv';
import Cookies from 'js-cookie';
const loginData = (!Cookies.get('loginSuccessBondiAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessBondiAdmin'));

const Categories = () => {
    const [catList, setcatList] 				= useState({});   
    const [CSVData, setCSVData] 				= useState([]);
    const [spinloader, setspinloader] 			= useState(0);   
    
    useEffect(() => {
		setspinloader(1);		
		  if (!loginData?.email) {
            window.location.href = `${config.baseUrl}login`
        }        
       getCatList();
       
    }, []) 
   const getCatList = async () => {
        let res = await getCategoriesListAction();
        if (res.success) {
            setcatList(res.data);
            if (res.data.length > 0) {
                let csvData = res.data;
                let csvAllData = [];
                for (let i = 0; i < csvData.length; i++) {
                    if (i == 0) {
                        csvAllData[i] = ['Name', 'Description', 'Meta title', 'Meta description', 'Meta keyword'];
                    }
                    csvAllData[i + 1] = [csvData[i].cat_name, csvData[i].cat_desc, csvData[i].meta_title,csvData[i].meta_desc,csvData[i].meta_keywords];
                }
                setCSVData(csvAllData);
            }
        }
        else
        {
			setcatList({});	
		}
    }
   const CatDelete = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this category ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await CategoryDeleteAction({ 'id': id });
                if (res.success) {
                    getCatList();
                    Swal.fire(
                        'Deleted!',
                        res.msg,
                        'success'
                    )
                } else {
                    Swal.fire(
                        'Failed!',
                        res.msg,
                        'error'
                    )
                }
            }
        })
    } 
   const columns = [
    {
      key: "Sno.",
      text: "#",
      cell: (row, index) => index + 1,
    },
    {
      key: "cat_id",
      text: "Cat ID"      
    },

    {
      key: "cat_name",
      text: "Category Name",
      cell: (item) => {
       return (
			
			<>
				<Link to={`${config.baseUrl}sub-category/` + item.cat_id}>
						{item.cat_name}
				</Link>
			</>	
			);		
      },
    },
    {
	key: "cat_flag",
	text: "Action",
	cell: (item) => {
		return (
			
			<>
				<div className="btn-group mb-5">
				{ item.cat_flag === 0 ?
					   <> <button type="button" className="btn btn-danger btn-sm " onClick={() => CategoryBlockUnBlock(item.cat_id, 1)}><i className='fa fa-unlock'></i> Inactive</button>
						&nbsp;&nbsp;&nbsp; <Link to={`${config.baseUrl}edit-category/` + item.cat_id}>
							<button className='btn btn-sm btn-primary'><i className='fa fa-pencil'></i>  Edit</button>
						</Link> &nbsp; 
				   &nbsp;&nbsp; <button type="button" className="btn btn-primary btn-sm " onClick={() => CatDelete(item.cat_id)}><i className='fa fa-trash'></i> Delete</button>
				   &nbsp; 
				   &nbsp;&nbsp; 
				   <Link to={`${config.baseUrl}sub-category/` + item.cat_id}>
						<button className='btn btn-danger btn-sm'>Sub-Categories</button>
					</Link>
				   </>
				
						: item.cat_flag === 1 ?
						   <> <button type="button" className="btn btn-primary btn-sm " onClick={() => CategoryBlockUnBlock(item.cat_id, 0)}><i className='fa fa-ban'></i> Active</button>
							&nbsp;&nbsp;&nbsp; <Link to={`${config.baseUrl}edit-category/` + item.cat_id}>
							<button className='btn btn-sm btn-primary'><i className='fa fa-pencil'></i>  Edit</button>
						</Link> &nbsp; 
				   &nbsp;&nbsp; <button type="button" className="btn btn-danger btn-sm " onClick={() => CatDelete(item.cat_id)}><i className='fa fa-trash'></i> Delete</button>&nbsp; 
				   &nbsp;&nbsp; 
				   <Link to={`${config.baseUrl}sub-category/` + item.cat_id}>
						<button className='btn btn-primary btn-sm'><i className='fa fa-th'></i> Sub-Categories</button>
					</Link>
				   
				   </>
				
							:
							'' 
						}
				</div>
			</>
		);
	}
    }
  ];

  const configForTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    pagination: "advance",
    button: {
      excel: false,
      print: false,
    },
  };
  
  const CategoryBlockUnBlock = async (id, is_active) => {
        Swal.fire({
            title: 'Are you sure?',
            text: is_active == 0 ? "You want to deactivate this category!”" : "You want to activate this category!”",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: is_active == 0 ? 'Yes, Deactivate it!' : 'Yes, Activate it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await CategoryBlockUnBlockAction({ 'id': id, 'cat_flag': is_active });
                if (res.success) {
                    getCatList();
                    Swal.fire(
                        is_active == 0 ? 'Deactivated!' : 'Activated',
                        res.msg,
                        'success'
                    )
                } else {
                    Swal.fire(
                        'Failed!',
                        res.msg,
                        'error'
                    )
                }
            }
        })
    }
	
	  
 
    return (

        <>
            <div className="wrapper">              
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="container-full">
                        {/* Main content */}
                        <div className="content-header">
                            <div className="d-flex align-items-center">
                                <div className="me-auto">
                                    <h3 className="page-title mb-5 pb-2">Category List</h3>
                                 </div>
                            </div>
                            <hr />
                        </div>
                        <section className="content">
                            <div className="row">
                                <div className="col-lg-12 col-12">
                                    <div className="box">
                                        <div className="box-header with-border">
											<div className="row mb-15">
												<h4 className="box-title col-md-2">Categories ({catList.length})</h4>
												<div className="col-md-10 text-right">
											   <a href={`${config.baseUrl}add-category`}
										className="btn btn-sm btn-primary add_btn"><i className='fa fa-plus'></i>  Add Category</a>
												</div>
                                        </div>
                                        
                                        </div>
                                       <div className="box-body">
                                            {CSVData.length > 0 ?
                                                <CSVLink data={CSVData} > <button className="btn-sm btn-primary"> Excel <i className="fa fa-file-excel-o" aria-hidden="true"></i></button> <br /><br /></CSVLink>
                                                : ""
                                            }
                                            <ReactDatatable
                                                config={configForTable}
                                                records={catList}
                                                columns={columns}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>                       
                    </div>
                </div>
                
          		
                <Footer />
            </div>
        </>


    )

}
export default Categories;
