import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import toast, { Toaster } from "react-hot-toast";
import config from "../coreFIles/config";
import Cookies from 'js-cookie'
import { updateSliderAction, getSliderDetailsAction } from "../Action/action";
import avt from '../assets/avata_profile.jpg'
import { Link, useParams } from 'react-router-dom';
const loginData = (!Cookies.get('loginSuccessBondiAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessBondiAdmin'));
const Editslider = () => {
	const {id} = useParams();
	const [spinloader, setspinloader] = useState(0);
	const [validatioError, setvalidatioError] = useState({});
	const [image_preview, setimage_preview] = useState('');
	const [sliderdata, setsliderdata] = useState({});

	useEffect(() => {
		if (!loginData?.email) {
			window.location.href = `${config.baseUrl}login`
		}
		
		getSliderDetails()
	}, [])
	
	const getSliderDetails= async() => {
        let res = await getSliderDetailsAction({id:id});
		if (res.success) {
			setsliderdata(res.data);
		}
		else
		{
			setsliderdata({});	
		}
    }
	
	const inputHandler = async (e) => {
		const { name, value } = e.target;
		setsliderdata((old) => {
			return { ...old, [name]: value };
		});
	};
	
	const PicChange = async (e) => {
        e.preventDefault()
        let image_as_base64 = URL.createObjectURL(e.target.files[0])
        let image_as_files = e.target.files[0];
		let imageType = e.target.files[0].type;
        if (imageType == 'image/jpeg' || imageType == 'image/jpg' || imageType == 'image/png' || imageType == 'image/gif') {
			setimage_preview(image_as_base64);
			setsliderdata((old) => {
				return { ...old, ['image_url']: image_as_files }
			})
		}
		else {
            toast.error('File type wrong please select GIF, JPG, JPEG or PNG.');
        }
        
    }
	
	function validate() {
		let imageError = "";
		
		if (sliderdata.image_url === "") {
			imageError = "Slider image is required.";
		}
		
		if (imageError ) {
			setvalidatioError({
				imageError
			});
			
			return false;
		}
		else {
			setvalidatioError({
				imageError
			});
			return true;
		}
	}
	
	const updateSlider = async (e) => {
		e.preventDefault()
		const isValid = validate();
		console.log(isValid);
		if (!isValid) {
		}
		else {
			setspinloader(1);
			let res = await updateSliderAction(sliderdata);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}sliders`;
				}, 2000);
			}
			else {
				setspinloader(0);
				toast.error(res.msg);
			}
		}
	}
	
	return (
		<>
			<div className="wrapper">
				<Header />
				<Toaster />
				<Sidebar />
				<div className="content-wrapper">
					<div className="container-full">
						<div className="content-header">
							<div className="d-flex align-items-center">
								<div className="me-auto">
									<h3 className="page-title mb-5 pb-2">Create Banner</h3>
								</div>
							</div>
							<hr />
						</div>
						{/* Content Header (Page header) */}

						{/* Main content */}
						<section className="content">
							<div className="row">
								<div className="col-lg-12 col-12">
									<div className="box">
										<div className="box-header with-border">
											<h4 className="box-title">Create Slider</h4>
											<a href={`${config.baseUrl}sliders`} className="btn btn-sm btn-primary add_btn">Back</a>
										</div>
										<div className="row mt-20 mb-50 ml-15 mr-15">
											<form onSubmit={updateSlider}>
												<div className="col-md-12">
													<div className="row">
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																	Image File <span className="req-star">* .gif, .jpg or .png only and max width 540px.</span>
																</label>
																<div className="col-md-12">
																	<input className="form-control" type="file" name="image_url" id='imageError'onChange={PicChange} />
																</div>
																<span className="validationErr">
																	{validatioError.imageError}
																</span>
															</div>
														</div>
														
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<div className="col-md-12">
																	{image_preview ?
																		<img id="ad_image" src={image_preview ? image_preview : ''} alt="slider" style={{ width: '30%' }} />
																		:
																		<img id="slider_image" src={`${config.bannersUrl + sliderdata?.image_url}`} alt="slider" style={{ width: '30%' }} />
																	}
																</div>
															</div>
														</div>
														
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																	Link to Website
																</label>
																<div className="col-md-12">
																	<input className="form-control" type="text" name="slide_url" id='slide_urlError' onChange={inputHandler} placeholder="Enter Banner URL" value={sliderdata?.slide_url} />
																</div>
															</div>
														</div>
														
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																Status
																</label>
																<div className="col-md-12">
																	<select name='status' className="form-control" id='statusError' onChange={inputHandler} value={sliderdata?.status}>
																		<option value="1">Acitve</option>
																		<option value="0">Inactive</option>
																	</select>
																</div>
															</div>
														</div>
														
														<div className="col-md-12">
														<br />
															<div className="text-center pull-left">
																{spinloader == '0' ?
																<button className='btn btn-primary'>Submit</button>
																:
																<button disabled className='btn btn-primary'>Submiting <i className="fa fa-spinner fa-spin validat"></i></button>
																}
															</div>
														</div>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</section>
						{/* /.content */}
					</div>
				</div>

				<Footer />
			</div>
		</>
	);
};
export default Editslider;
