import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import toast, { Toaster } from "react-hot-toast";
import JoditEditor from "jodit-react";
import config from "../coreFIles/config";
import moment from 'moment';
import ReactDatatable from '@ashvin27/react-datatable';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import { updateAdAction, getAdDetailAction, getParentCatsAction, getChildCatsAction, getSearchSuburbAction, getPremiumPlansAction, getFeaturedPlansAction, getPlanDetailsAction, getAdPaymentHistoryAction, addAdPlanAction, addAdImgAction, getAdImagesAction, AdImageDeleteAction } from "../Action/action";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import avt from '../assets/avata_profile.jpg';
import { Link, useParams } from 'react-router-dom';

const loginData = (!Cookies.get('loginSuccessBondiAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessBondiAdmin'));
const Adedit = () => {
    const editorHeight = '400px';
	const { id } = useParams();
	const [spinloader, setspinloader] = useState(0);
	const [imgspinloader, setimgspinloader] = useState(0);
	const [currentDate, setcurrentDate] = useState(new Date());
	const [validatioError, setvalidatioError] = useState({});
	const [planvalidatioError, setplanvalidatioError] = useState({});
	const [parentcatsList, setparentCats] 	= useState([]);
	const [premiumPlans, setpremiumPlans] 	= useState([]);
	const [featuredPlans, setfeaturedPlans]	= useState([]);
	const [childcatsList, setchildCats] 	= useState([]);
	const [SuburbsearchResults, setSuburbsearchResults] 	= useState([]);
	const [suburb, setsuburb] 	= useState('');
    const [adpaymentdata, setadpaymentdata] = useState([]);
	const [addata, setaddata] = useState({});
	const [adimagesdata, setadImagesdata] = useState({});
	const [imgdata, setimgdata] = useState({});	
	const [adplandata, setadplandata] = useState({
        ad_id: id,
        plan_id: '',
        from_date: '',
        planAmount: '',
        planDays: '',
        to_date: ''
    });
	
	useEffect(() => {
		if (!loginData?.email) {
			window.location.href = `${config.baseUrl}login`
		}     
		
		getAdDetails();
        getAdPaymentHistory();
        getAdImagesAPI();
		getParentCats();
		getPremiumPlans();
		getFeaturedPlans();
            
	}, [])
	
	const getAdDetails = async () => {
		let res = await getAdDetailAction({id:id});
		if (res.success) {
			setaddata(res.data);
			getChildCats(res.data);  
		}
		else
		{
			setaddata({});	
		}
	}
    
	const getAdPaymentHistory = async () => {
		let res = await getAdPaymentHistoryAction({ id: id });
		if (res.success) {
			setadpaymentdata(res.data);
		}
		else {
			setadpaymentdata({});
		}
	}
	
	const getAdImagesAPI = async () => {
		let res = await getAdImagesAction({id:id});
		if (res.success) {
			setadImagesdata(res.data);
		}
		else
		{
			setadImagesdata({});	
		}
	}
	
	const getParentCats = async () => {
		let res = await getParentCatsAction();
		if (res.success) {
			setparentCats(res.data);
		}
		else{
			setparentCats({});
		}
	}
	
	const getPremiumPlans = async () => {
		let res = await getPremiumPlansAction();
		if (res.success) {
			setpremiumPlans(res.data);
		}
		else{
			setpremiumPlans({});
		}
	}
	
	const getFeaturedPlans = async () => {
		let res = await getFeaturedPlansAction();
		if (res.success) {
			setfeaturedPlans(res.data);
		}
		else{
			setfeaturedPlans({});
		}
	}
	
	const getChildCats = async(data) => {
		setimgspinloader(1);
		let res = await getChildCatsAction({id:data.parent_id});
		if (res.success) {
			setchildCats(res.data);
		}
		else{
			setchildCats({});
		}
		setimgspinloader(0);
	};
	
	
		
	const inputHandler = async (e) => {
		const { name, value } = e.target;
		setaddata((old) => {
			return { ...old, [name]: value };
		});
	};  
	
	function formatDate(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

	function adddaysToDate(days) {
		var cdate	= new Date();
		var result	= cdate.setDate(cdate.getDate() + days);
		var newdate	= formatDate(result);
		
        return newdate;
    }

	const whatsonDate = e => {
        let whtsonDate = formatDate(e);
		setaddata((old) => {
			return { ...old, 'whatson_date': whtsonDate }
		})
    }
    
	const descriptionHandler = async (e) => {
		setaddata((old) => {
		  return { ...old, 'description': e }
		})
	}
	
	const inputplanHandler = async (e) => {
		const { name, value } = e.target;

		let fromDate	= formatDate(currentDate);
			
		let res = await getPlanDetailsAction({id:value});
		if (res.success) {
			let toDate	= adddaysToDate(res.data.days);
			
			setadplandata((old) => {
				return { ...old,
					'from_date': fromDate,
					'to_date': toDate,
					'planDays': res.data.days,
					'planAmount': res.data.price,
					[name]: value 
				};
			});
		}else{
			setadplandata((old) => {
				return { ...old,
					'from_date': '',
					'planDays': '',
					'to_date': '',
					'planAmount': '',
					[name]: 0
				};
			});
		}
	};

	const inputwork_typeHandler = async (e) => {
		const { name, value } = e.target;

		if (value == 0) {
			setaddata((old) => {
				return { ...old, [name]: 0 };
			});
		} else {
			setaddata((old) => {
				return { ...old, [name]: value };
			});
		}
	};

	const checkboxHandler = (e) => {
		const { name, value } = e.target;
		if (e.target.checked) {
			setaddata((old) => {
				return { ...old, [name]: value };
			});
		} else {
			setaddata((old) => {
				return { ...old, [name]: 0 };
			});
		} 
	};

	const SearchSuburb = async (str) => {
		try {
			setsuburb(str);
			let res = await getSearchSuburbAction({str:str});
			setSuburbsearchResults(res.data);
		}
		catch (error) {
			console.error(error);
		}
	};

	const handleSelectSuburb = (row) => {
		setsuburb(row);
		setaddata((old) => {
			return { ...old, 'suburb': row };
		});
		setSuburbsearchResults({});
	};
	
	const adImageDelete = async (imgid) => {
		Swal.fire({
			title: 'Are you sure? ',
			text: "You want to Delete this Image!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
		}).then(async (result) => {
			if (result.isConfirmed) {
				let res = await AdImageDeleteAction({'id':imgid});
				if (res.success) {
					getAdImagesAPI();
					Swal.fire(
						'Deleted!',
						res.msg,
						'success'
					)
				} else {
					Swal.fire(
						'Failed!',
						res.msg,
						'error'
					)
				}
			}
		});
	}
	
	const columns = [
		{
			key: "Sno.",
			text: "#",
			cell: (row, index) => index + 1,
			width: '5%', 
		},
		{
			key: "adid",
			text: "Ad Id",
			width: '10%',
		},
        {
			key: "receviedOn",
			text: "Recevied On",
			width: '15%',
			cell: (item) => {
				return (
					`${moment(item.receivedOn).format('DD/MM/YYYY')}`
				);
			}
		},
		{
			key: "amountReceived",
			text: "Amount Received",  
			width: '17%',
		},
		{
			key: "plan",
			text: "Plan",
			width: '15%',
		},
		{
			key: "planDays",
			text: "Plan Days",
			width: '12%',   
		},
		{
			key: "currency",
			text: "Currency",
			width: '10%',   
		},
		{
			key: "txn_id",
			text: "Transaction Id",
			width: '16%',
		},
	];
	
	const Imagecolumns = [
		{
			key: "Sno.",
			text: "#",
			cell: (row, index) => index + 1,
			width: '5%', 
		},
		
        {
			key: "addImg",
			text: "Image",			
			cell: (item) => {
				return (
					<>
						<img src={config.apiUrl+`/`+item.addImg} style={{ width: '200px' }} />
					</>	
				);
			}
		},
		{
			key: "action",
			text: "Action",
			width: '15%',
			cell: (item) => {
				return (
					<>
						<div className="btn-group mb-5">						
							<button type="button" className="btn btn-danger btn-sm " onClick={() => adImageDelete(item.imgId)}><i className='fa fa-trash'></i> Delete</button>
						</div>
					</>
				);
			}
		}
		
	];
	
	const configForTable = {
		page_size: 10,
		length_menu: [10, 20, 50],
		show_filter: true,
		show_pagination: true,
		pagination: "advance",
		button: {
			excel: false,
			print: false,
		},
	};
	
	function validate() {	 		
		let parentError = "";
		let categoryError = "";
		let titleError = "";
		let description = "";
		let suburbError = "";
		let areaError = "";
		let statusError = "";
		
		if (addata.parent_id === "") {
			parentError = "Category is required.";
		}
		
		if (addata.category_id === "") {
			categoryError = "Sub Category is required.";
		}
		
		if (addata.title === "") {
			titleError = "Ad title is required.";
		}
		
		if (addata.discription === "") {
			description = "Description is required.";
		}
		
		if (addata.status === "") {
			statusError = "Status is required.";
		}
		
		if (addata.suburb === "") {
			suburbError = "Suburb is required.";
		}
		
		if (addata.area === "") {
			areaError = "Area is required.";
		}
		
		if (titleError || parentError || categoryError || description || statusError || suburbError || areaError) {
			setvalidatioError({
				titleError,parentError,categoryError,description,statusError,suburbError,areaError });
			return false;
		}
		else {
			setvalidatioError({
			titleError,parentError,categoryError,description,statusError,suburbError,areaError });
			return true;
		}
	}
	
	function validateplan() {
        let planError = "";
		
		if (adplandata.plan_id === "") {
            planError = "Plan is required.";
		}
        
        if (planError) {
			setplanvalidatioError({ planError });
			return false;
		}
		else {
			setplanvalidatioError({ planError });
			return true;
		}
    }
	
	const handleKeyPress = (e) => {
		// Allow only numeric values (0-9) and certain control keys
		const isValidInput = /^[0-9\b]+$/.test(e.key);
		
		if (!isValidInput) {
			e.preventDefault();
		}
	};
	
	const updateAd = async (e) => {
		e.preventDefault()
		const isValid = validate();
		
		if (!isValid) {		
		}
		else {
			setspinloader(1);				                             
			let res = await updateAdAction(addata);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}ad-list`;
				}, 2000);
			}			 
			else {
				setspinloader(0);
				toast.error(res.msg);
			}
		} 
	}
	
	const addadPlan = async (e) => {
		e.preventDefault()
		const isValid = validateplan();
		
		if (!isValid) {		
		}
		else {
			setspinloader(1);				                             
			let res = await addAdPlanAction(adplandata);
			if (res.success) {
                setspinloader(0);
				toast.success(res.msg);
                getAdPaymentHistory();
			}			 
			else {
				setspinloader(0);
				toast.error(res.msg);
			}
		} 
	}
	
	const inputfileHandler = (e) => {
		e.preventDefault()
		let files = Array.from(e.target.files);		
	   setimgdata(files);
	};
	
	const addadImages = async (e) => {
		e.preventDefault()
		const isValid = validate();
		
		if (!isValid) {		
		}
		else {
			setspinloader(1);				                             
			let res = await addAdImgAction({images:imgdata,id:id});
			if (res.success) {
				toast.success(res.msg);
				getAdImagesAPI();
				setimgdata({});
			}			 
			else {
				setspinloader(0);
				toast.error(res.msg);
			}
		} 
	}
	
	
	return (
		<>
			<div className="wrapper">
				<Header />
				<Toaster />
				<Sidebar />
				<div className="content-wrapper">
					<div className="container-full">
						<div className="content-header">
							<div className="d-flex align-items-center">
								<div className="me-auto">
									<h3 className="page-title mb-5 pb-2">Edit Ad</h3>
								</div>
							</div>
							<hr />
						</div>
						{/* Content Header (Page header) */}

						{/* Main content */}
						<section className="content">
							<div className="row">
								<div className="col-lg-12 col-12">
									<div className="box">
										<div className="box-header with-border">
											<h4 className="box-title">Edit Ad</h4>
											<a href={`${config.baseUrl}ad-list`} className="btn btn-sm btn-primary add_btn">Back</a> 
										</div>
										<div className="row mt-20 mb-50 ml-15 mr-15">
                                            <Tabs>
                                                <TabList>
                                                    <Tab>Ad Details</Tab>
                                                    <Tab>Payment History</Tab>       
                                                    <Tab>Ad Images</Tab>       
                                                </TabList>
                                                <TabPanel>
                                                    <form onSubmit={updateAd}>
                                                        <div className="col-md-12">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="form-group row mb-1">
                                                                        <label className="col-form-label col-md-12">
                                                                            Category <span className="req-star">*</span>
                                                                        </label>
                                                                        <div className="col-md-12">
                                                                            <select name='parent_id' className="form-control" id='parentError' disabled value={addata?.parent_id}>
                                                                                <option value="">Select Category</option>  
                                                                                { parentcatsList.length >0 ?
                                                                                    parentcatsList.map((item)=>{
                                                                                        return(
                                                                                            <option value={item.cat_id}>{item.cat_name}</option>
                                                                                        )
                                                                                    })
                                                                                    :''
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                        <span className="validationErr">
                                                                            {validatioError.parentError}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="col-md-6">
                                                                    <div className="form-group row mb-1">
                                                                        <label className="col-form-label col-md-12">
                                                                            Sub Category <span className="req-star">*</span>
                                                                        </label>
                                                                        <div className="col-md-12">
                                                                         { imgspinloader==0 ?
                                                                            <select name='category_id' className="form-control" id='categoryError' onChange={inputwork_typeHandler} value={addata?.category_id}>
                                                                                <option value="">Select Subcategory</option>  
                                                                               { childcatsList.length >0 ?
                                                                                    childcatsList.map((item)=>{
                                                                                        return(
                                                                                            <option value={item.cat_id}>{item.cat_name}</option>
                                                                                        )
                                                                                    })
                                                                                    :''
                                                                                    
                                                                                }
                                                                            </select>
                                                                            :'' }
                                                                        </div>
                                                                        <span className="validationErr">
                                                                            {validatioError.categoryError}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="col-md-6">
                                                                    <div className="form-group row mb-1">
                                                                        <label className="col-form-label col-md-12">
                                                                            Title <span className="req-star">*</span>
                                                                        </label>
                                                                        <div className="col-md-12">
                                                                            <input className="form-control" type="text" name="title" id='titleError' onChange={inputHandler} placeholder="Enter Title" value={addata?.title} />
                                                                        </div>
                                                                        <span className="validationErr">
                                                                            {validatioError.titleError}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="form-group row mb-1">
                                                                        <label className="col-form-label col-md-12">Description</label>
                                                                        <div className="col-md-12">
                                                                            <JoditEditor
                                                                                onChange={descriptionHandler}
                                                                                value={addata?.discription}
                                                                                name="discription"
                                                                                id="description"
                                                                                style={{ height: editorHeight }}
                                                                                className="form-control"
                                                                                placeholder="Enter description"
                                                                                />						
                                                                        </div>
                                                                        <span className="validationErr">
                                                                            {validatioError.description}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group row mb-1">
                                                                        <label className="col-form-label col-md-12">
                                                                            Suburb<span className="req-star">*</span>                    
                                                                        </label>
                                                                        <div className="col-md-12">
                                                                            <input type="text" name="suburb" className="form-control" id="suburb"  onChange={(e) => SearchSuburb(e.target.value)} autocomplete="off" value={addata?.suburb!=="" ? addata?.suburb:suburb } />
                                                                        </div>
                                                                        <span className="validationErr">
                                                                            {validatioError.suburbError}
                                                                        </span>
                                                                        {SuburbsearchResults.length > 0 ? (
                                                                            <ul className="suburbList">
                                                                                {SuburbsearchResults.map((row) => (
                                                                                    <li key={row.id} onClick={() => handleSelectSuburb(row.rowlocation)}>
                                                                                        {row.rowlocation} 
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="col-md-6">
                                                                    <div className="form-group row mb-1">
                                                                        <label className="col-form-label col-md-12">
                                                                            Area <span className="req-star">*</span>
                                                                        </label>
                                                                        <div className="col-md-12">
                                                                            <select name='area' className="form-control" id='areaError' onChange={inputwork_typeHandler} value={addata?.area}>
                                                                                <option value="">Select</option>  
                                                                                <option value="Australia Wide">Australia Wide</option>
                                                                                <option value="NSW - Sydney">NSW - Sydney</option>
                                                                                <option value="NSW - Regional">NSW - Regional</option>
                                                                                <option value="QLD - Brisbane">QLD - Brisbane</option>
                                                                                <option value="QLD - Regional">QLD - Regional</option>
                                                                                <option value="VIC - Melbourne">VIC - Melbourne</option>
                                                                                <option value="VIC - Regional">VIC - Regional</option>
                                                                                <option value="WA - Perth">WA - Perth</option>
                                                                                <option value="WA - Regional">WA - Regional</option>
                                                                                <option value="SA - Adelaide">SA - Adelaide</option>
                                                                                <option value="SA - Regional">SA - Regional</option>
                                                                                <option value="NT - Darwin">NT - Darwin</option>
                                                                                <option value="NT - Regional">NT - Regional</option>
                                                                                <option value="TAS - Hobart">TAS - Hobart</option>
                                                                                <option value="TAS - Regional">TAS - Regional</option>
                                                                            </select>
                                                                        </div>
                                                                        <span className="validationErr">
                                                                            {validatioError.areaError}
                                                                        </span>
                                                                    </div>
                                                                </div>                                              
                                                                
                                                                { addata?.parent_id=='1'?
                                                                <>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group row mb-1">
                                                                            <label className="col-form-label col-md-12">
                                                                                Age Group
                                                                            </label>
                                                                            <div className="col-md-12">
                                                                                <select name='age' className="form-control" id='age_groupError' onChange={inputwork_typeHandler} value={addata?.age}>
                                                                                    <option value="">Select</option>
                                                                                    <option value="18-22">18-22</option>
                                                                                    <option value="23-26">23-26</option>
                                                                                    <option value="27-30">27-30</option>
                                                                                    <option value="31-36">31-36</option>
                                                                                    <option value="37-42">37-42</option>
                                                                                    <option value="43-50">43-50</option>
                                                                                    <option value="50-60">50-60</option>
                                                                                    <option value="over 61">over 61</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group row mb-1">
                                                                            <label className="col-form-label col-md-12">
                                                                                Gender
                                                                            </label>
                                                                            <div className="col-md-12">
                                                                                <select name='sex' className="form-control" id='genderError' onChange={inputwork_typeHandler} value={addata?.sex}>
                                                                                    <option value="">Select</option>
                                                                                    <option value="0">Male</option>
                                                                                    <option value="1">Female</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                : ''}
                                                                {addata?.parent_id=='2'?
                                                                <>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group row mb-1">
                                                                            <label className="col-form-label col-md-12">
                                                                                Property Type
                                                                            </label>
                                                                            <div className="col-md-12">
                                                                                <select name='property_type' className="form-control" id='property_typeError' onChange={inputwork_typeHandler} value={addata?.property_type}>
                                                                                    <option value="">Select</option>
                                                                                    <option value="All">All</option>
                                                                                    <option value="Studio">Studio</option>
                                                                                    <option value="Apartment">Apartment</option>
                                                                                    <option selected="selected" value="House">House</option>
                                                                                    <option value="Rural">Rural</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group row mb-1">
                                                                            <label className="col-form-label col-md-12">
                                                                                Bedrooms
                                                                            </label>
                                                                            <div className="col-md-12">
                                                                                <select name='bed_rooms' className="form-control" id='bedroomsError' onChange={inputwork_typeHandler} value={addata?.bed_rooms}>
                                                                                    <option value="">Select</option>
                                                                                    <option value="All">All</option>
                                                                                    <option value="1 or more">1 or more</option>
                                                                                    <option value="2 or more">2 or more</option>
                                                                                    <option value="3 or more">3 or more</option>
                                                                                    <option value="4 or more">4 or more</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group row mb-1">
                                                                            <label className="col-form-label col-md-12">
                                                                                Unit/Flat
                                                                            </label>
                                                                            <div className="col-md-12">
                                                                                <input type="text" name='unit_flat' className="form-control" id='unit_flatError'  onChange={inputHandler} value={addata?.unit_flat}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group row mb-1">
                                                                            <label className="col-form-label col-md-12">
                                                                                Street No
                                                                            </label>
                                                                            <div className="col-md-12">
                                                                                <input type="text" name='street_number' className="form-control" id='street_noError'  onChange={inputHandler} value={addata?.street_number}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group row mb-1">
                                                                            <label className="col-form-label col-md-12">
                                                                                Street Name
                                                                            </label>
                                                                            <div className="col-md-12">
                                                                                <input type="text" name='street_name' className="form-control" id='street_nameError'  onChange={inputHandler} value={addata?.street_name} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group row mb-1">
                                                                            <label className="col-form-label col-md-12">
                                                                                Price
                                                                            </label>
                                                                            <div className="col-md-12">
                                                                                <input type="text" name='sell_price' className="form-control" id='priceError'  onChange={inputHandler} onKeyPress={handleKeyPress} value={addata?.sell_price} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                : ''}
                                                                {addata?.parent_id=='3'?
                                                                <>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group row mb-1">
                                                                            <label className="col-form-label col-md-12">
                                                                                Price
                                                                            </label>
                                                                            <div className="col-md-12">
                                                                                <input type="text" name='sell_price' className="form-control" id='priceError'  onChange={inputHandler} onKeyPress={handleKeyPress} value={addata?.sell_price} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                : ''}
                                                                {addata?.parent_id=='4'?
                                                                <>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group row mb-1">
                                                                            <label className="col-form-label col-md-12">
                                                                                When is the Event?
                                                                            </label>
                                                                            <div className="col-md-12">
                                                                                <DatePicker onChange={whatsonDate}  placeholder ="Select Date" autoComplete="off" name="date_whatson" id="whatson_dateError" className='form-control' value={formatDate(addata?.date_whatson)} selected={addata?.date_whatson ? new Date(addata?.date_whatson) : new Date()} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group row mb-1">
                                                                            <label className="col-form-label col-md-12">
                                                                                Day/Night Event?
                                                                            </label>
                                                                            <div className="col-md-12">
                                                                                <select name='time_whatson' className="form-control" id='event_typeError' onChange={inputwork_typeHandler} value={addata?.time_whatson}>
                                                                                    <option value="">Select</option>
                                                                                    <option value="Day">Day</option>
                                                                                    <option value="Night">Night</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                : ''}
                                                                {addata?.parent_id=='5'?
                                                                <>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group row mb-1">
                                                                            <label className="col-form-label col-md-12">
                                                                                Work Type
                                                                            </label>
                                                                            <div className="col-md-12">
                                                                                <select name='job_work_type' className="form-control" id='work_typeError' value={addata?.job_work_type} onChange={inputwork_typeHandler}>
                                                                                    <option value="">Select Work Type</option>
                                                                                    <option value="All">All</option>
                                                                                    <option value="Full Time">Full Time</option>
                                                                                    <option value="Part Time">Part Time</option>
                                                                                    <option value="Contract/Temp">Contract/Temp</option>
                                                                                    <option value="Freelance">Freelance</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                : ''}
                                                                {addata?.parent_id=='7'?
                                                                <>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group row mb-1">
                                                                            <label className="col-form-label col-md-12">
                                                                                ABN (Australian Business Number)
                                                                            </label>
                                                                            <div className="col-md-12">
                                                                                <input className="form-control" type="text" name="abn" id='abnError' onChange={inputHandler} placeholder="Enter Australian Business Number" onKeyPress={handleKeyPress} value={addata?.abn} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group row mb-1">
                                                                            <label className="col-form-label col-md-12">
                                                                                Accreditation
                                                                            </label>
                                                                            <div className="col-md-12">
                                                                                <input className="form-control" type="text" name="accreditation" id='accreditationError' onChange={inputHandler} placeholder="Enter Accreditation" value={addata?.accreditation} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :''
                                                                }
                                                               
                                                                <div className="col-md-6">
                                                                    <div className="form-group row mb-1">
                                                                        <label className="col-form-label col-md-12">
                                                                            Phone Number 
                                                                        </label>
                                                                        <div className="col-md-12">
                                                                            <input className="form-control" type="text" name="phone" id='phoneError' onChange={inputHandler} placeholder="Enter Phone Number" onKeyPress={handleKeyPress} value={addata?.phone} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="col-md-6">
                                                                    <div className="form-group row mb-1">
                                                                        <label className="col-form-label col-md-12">
                                                                            Youtube Video URL 
                                                                        </label>
                                                                        <div className="col-md-12">
                                                                            <input className="form-control" type="text" name="youtube_url" id='yturl_Error' onChange={inputHandler} placeholder="Enter youtube video url" value={addata?.youtube_url} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="col-md-6">
                                                                    <div className="form-group row mb-1">
                                                                        <label className="col-form-label col-md-12">
                                                                            Tags 
                                                                        </label>
                                                                        <div className="col-md-12">
                                                                            <input className="form-control" type="text" name="tags" id='tags_Error' onChange={inputHandler} placeholder="Enter ad tags" value={addata?.tags} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="col-md-6">
                                                                    <div className="form-group row mb-1">
                                                                        <label className="col-form-label col-md-12">
                                                                            Hide My ID 
                                                                        </label>
                                                                        <div className="col-md-12">
                                                                            <input className="" type="checkbox" name="hide_my_id" id='hdmyid_Error' onChange={checkboxHandler} value="1" checked={addata?.hide_my_id==1 ? 'checked':''} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="col-md-6">
                                                                    <div className="form-group row mb-1">
                                                                        <label className="col-form-label col-md-12">
                                                                        Status <span className="req-star">*</span>
                                                                        </label>
                                                                        <div className="col-md-12">
                                                                            <select name='status' className="form-control" id='statusError' onChange={inputwork_typeHandler} value={addata?.status}>
                                                                                <option value="">-- Please select --</option>
                                                                                <option value="0">Inactive</option>
                                                                                <option value="1">Active</option>
                                                                            </select>	
                                                                        </div>
                                                                        <span className="validationErr">
                                                                            {validatioError.statusError}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="col-md-12">
                                                                    <div className="text-center pull-left mt-3">
                                                                        {spinloader == '0' ?
                                                                            <button className='btn btn-primary' >Update</button>
                                                                            :
                                                                            <button disabled className='btn btn-primary' >Updating <i className="fa fa-spinner fa-spin validat"></i></button>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>                                                   
                                                        </div>
                                                    </form>
                                                </TabPanel>
                                                <TabPanel>
                                                    <form onSubmit={addadPlan}>
                                                        <div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																Plan <span className="req-star">*</span>
																</label>
																<div className="col-md-12">
																	<select name='plan_id' className="form-control" id='planError' onChange={inputplanHandler} >
																		<option value="">Select Plan</option>
																		
																		{ premiumPlans.length >0 ?
																			premiumPlans.map((item)=>{
																				return(
																					<option value={item.plan_id}>{item.name+" ($"+ item.price +")"}</option>
																				)
																			})
																			:''
																		}
																		{ featuredPlans.length >0 ?
																			featuredPlans.map((item)=>{
																				return(
																					<option value={item.plan_id}>{item.name +" ($"+ item.price +")"}</option>
																				)
																			})
																			:''
																		}
																	</select>	
																</div>
                                                                <span className="validationErr">
                                                                    {planvalidatioError.planError}
                                                                </span>
															</div>
														</div>
                                                        <div className="col-md-12">
															<div className="float-left mt-3">
																{spinloader == '0' ?
																	<button className='btn btn-primary' >Submit</button>
																	:
																	<button disabled className='btn btn-primary' >Submiting <i className="fa fa-spinner fa-spin validat"></i></button>
																}
															</div>
														</div>
                                                    </form>
                                                    <hr/>
                                                    <div className="box-body">
                                                        <ReactDatatable
                                                            config={configForTable}
                                                            records={adpaymentdata}
                                                            columns={columns}
                                                        />
                                                    </div>
                                                </TabPanel>
                                                <TabPanel>
                                                    <form onSubmit={addadImages}>
														<div className="row">
                                                        <div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																	Images
																</label>
																<div className="col-md-12">
																	<input className="form-control" type="file" name="images" id='imagesError' onChange={inputfileHandler} multiple />
																</div>
															</div>
                                                                <span className="validationErr">
                                                                    {planvalidatioError.planError}
                                                                </span>															
														</div>
                                                        <div className="col-md-6">
															<div className="float-left mt-25">
																{imgspinloader == '0' ?
																	<button className='btn btn-primary' >Submit</button>
																	:
																	<button disabled className='btn btn-primary' >Submiting <i className="fa fa-spinner fa-spin validat"></i></button>
																}
															</div>
														</div>
														</div>
                                                    </form>
                                                    <hr/>
                                                    <div className="box-body">
                                                        <ReactDatatable
                                                            config={configForTable}
                                                            records={adimagesdata}
                                                            columns={Imagecolumns}
                                                        />
                                                    </div>
                                                </TabPanel>
                                            </Tabs>
										</div>
									</div>
								</div>
							</div>
						</section>
						{/* /.content */}
					</div>
				</div>
				<Footer />
			</div>
		</>
	);
};
export default Adedit;
