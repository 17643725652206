import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import toast, { Toaster } from "react-hot-toast";
import JoditEditor from "jodit-react";
import config from "../coreFIles/config";
import Cookies from 'js-cookie'
import { createContentAction, getMasterContentAction } from "../Action/action";
import avt from '../assets/avata_profile.jpg'
import { Link } from 'react-router-dom';

const loginData = (!Cookies.get('loginSuccessBondiAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessBondiAdmin'));
const Addcontent = () => {
    const editorHeight = '400px';
	const [spinloader, setspinloader] = useState(0);
	const [validatioError, setvalidatioError] = useState({});
    const [contentList, setContentList] = useState('');
	const [contentdata, setcontentdata] = useState({
		content_id: '',		
		title: '',
		description: '',
		meta_title: '',
		meta_keyword: '',
		meta_description: '',
		sidemenu: 0,
		footermenu: 0,
		status: 1,
	});

	useEffect(() => {
		if (!loginData?.email) {
			window.location.href = `${config.baseUrl}login`
		}
		
		getContentlist();
	}, [])

	const getContentlist = async () => {
		let res = await getMasterContentAction();
		if (res.success) {
			let data = res.data;
			console.log(data);
			setContentList(data);	
		}
		else
		{
			setContentList([]);	
		}
	}

	const inputHandler = async (e) => {
		const { name, value } = e.target;
		setcontentdata((old) => {
			return { ...old, [name]: value };
		});
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (e.target.checked) {
			setcontentdata((old) => {
				return { ...old, [name]: value };
			});
		} else {
			setcontentdata((old) => {
				return { ...old, [name]: 0 };
			});
		} 
	}; 

    const descriptionHandler = async (e) => {
		setcontentdata((old) => {
		  return { ...old, 'description': e }
		})
    }

	function validate() {
		let titleError = "";
		let content_idError = "";
		let descriptionError = "";

		if (contentdata.title === "") {
			titleError = "Title is required.";
		}
		
		if (contentdata.content_id === "") {
			content_idError = "Content is required.";
		}
		
		if (contentdata.description === "") {
			descriptionError = "Description is required.";
		}
		
		
		if (titleError || content_idError || descriptionError ) {
			setvalidatioError({
				titleError, content_idError, descriptionError
			});
			
			return false;
		}
		else {
			setvalidatioError({
				titleError, content_idError, descriptionError
			});
			return true;
		}
	}
	
	const createContent = async (e) => {
		e.preventDefault()
		const isValid = validate();
		console.log(isValid);
		if (!isValid) {
		}
		else {
			setspinloader(1);
			let res = await createContentAction(contentdata);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}content-pages`;
				}, 2000);
			}
			else {
				setspinloader(0);
				toast.error(res.msg);
			}
		}
	}
	
	return (
		<>
			<div className="wrapper">
				<Header />
				<Toaster />
				<Sidebar />
				<div className="content-wrapper">
					<div className="container-full">
						<div className="content-header">
							<div className="d-flex align-items-center">
								<div className="me-auto">
									<h3 className="page-title mb-5 pb-2">Create Content Page</h3>
								</div>
							</div>
							<hr />
						</div>
						{/* Content Header (Page header) */}

						{/* Main content */}
						<section className="content">
							<div className="row">
								<div className="col-lg-12 col-12">
									<div className="box">
										<div className="box-header with-border">
											<h4 className="box-title">Create Content Page</h4>
											<a href={`${config.baseUrl}content-pages`} className="btn btn-sm btn-primary add_btn">Back</a>
										</div>
										<div className="row mt-20 mb-50 ml-15 mr-15">
											<form onSubmit={createContent}>
												<div className="col-md-12">
													<div className="row">
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																	Content <span className="req-star">*</span>
																</label>
																<div className="col-md-12">
																	<select name='content_id' className="form-control" id='content_idError' onChange={inputHandler}>
																		<option value="">Select</option>
																		{contentList.length> 0 ?
																			contentList.map(cntnt => (
																				<option value={cntnt.id}>{cntnt.title}</option>
																			)) : ''
																		}
																	</select>
                                                                    <span className="validationErr">
                                                                        {validatioError.content_idError}
                                                                    </span>
																</div>
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																	Ad Title <span className="req-star">*</span>
																</label>
																<div className="col-md-12">
																	<input className="form-control" type="text" name="title" id='titleError' onChange={inputHandler} placeholder="Enter title" />
																</div>
																<span className="validationErr">
																	{validatioError.titleError}
																</span>
															</div>
														</div>
														
														<div className="col-md-12">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">Description <span className="req-star">*</span> </label>
																<div className="col-md-12">
																	<JoditEditor
																		onChange={descriptionHandler}
																		value=''
																		name="description"
																		id="descriptionError"
																		style={{ height: editorHeight }}
																		className="form-control"
																		placeholder="Enter description"
																		/>						
																</div>	
                                                                <span className="validationErr">
																	{validatioError.descriptionError}
																</span>				
															</div>
														</div>
														
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																Meta Title </label>
																<div className="col-md-12">
                                                                    <input className="form-control" type="text" name="meta_title" id='meta_titleError' onChange={inputHandler} placeholder="Enter Meta title" />
																</div>
																
															</div>
														</div>
														
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																Meta Keyword </label>
																<div className="col-md-12">
                                                                    <input className="form-control" type="text" name="meta_keyword" id='meta_keywordError' onChange={inputHandler} placeholder="Enter Meta keyword" />
																</div>
																
															</div>
														</div>
														
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																Meta description </label>
																<div className="col-md-12">
                                                                    <textarea className="form-control" type="text" name="meta_description" id='meta_descriptionError' onChange={inputHandler} rows="2" placeholder="Enter Meta description"></textarea>
																</div>
																
															</div>
														</div>
														
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																Show In </label>
																<div className="col-md-12">
                                                                    <input className="" type="checkbox" name="sidemenu" id='sidemenuError' onChange={handleChange} value="1"/> Show in sidebar menu 
                                                                    &nbsp;&nbsp;
                                                                    <input className="" type="checkbox" name="footermenu" id='footermenuError' onChange={handleChange} value="1"/> Show in footer menu
																</div>
																
															</div>
														</div>
														
														<div className="col-md-12">
															
															<br />
															<div className="text-center pull-left">
																{spinloader == '0' ?
																<button className='btn btn-primary'>Submit</button>
																:
																<button disabled className='btn btn-primary'>Submiting <i className="fa fa-spinner fa-spin validat"></i></button>
																}
															</div>
														</div>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</section>
						{/* /.content */}
					</div>
				</div>

				<Footer />
			</div>
		</>
	);
};
export default Addcontent;
