import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import toast, { Toaster } from "react-hot-toast";
import config from "../coreFIles/config";
import Cookies from 'js-cookie'
import { createBannerAction, getCategoriesListAction, getChildCategoriesListAction } from "../Action/action";
import avt from '../assets/avata_profile.jpg'
import { Link } from 'react-router-dom';
const loginData = (!Cookies.get('loginSuccessBondiAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessBondiAdmin'));
const Addbanner = () => {
	const [CategoriesList, setCategories]			= useState([]);
	const [ChildCategoriesList, setChildCategories]	= useState([]);
	const [spinloader, setspinloader] = useState(0);
	const [validatioError, setvalidatioError] = useState({});
	const [selectedpages, setSelectedpages] = useState([]);	
	const [bannerdata, setbannerdata] = useState({
		ad_title: '',
		ad_url: '',
		ad_type: 'img',
		onhome: 1,
		ad_pages: [],
		ad_allpages: 1,
		ad_genpages: 1,		
		ad_file: '',
		status: '',
		ad_column: 'B'
	});

	useEffect(() => {
		if (!loginData?.email) {
			window.location.href = `${config.baseUrl}login`
		}
		categoriesList();
		childcategoriesList();
	}, [])

	const categoriesList = async () => {
		let res = await getCategoriesListAction();
		if (res.success) {
			let data = res.data;			
			setCategories(data);	
		}
		else
		{
			setCategories([]);	
		}
	}
	
	const childcategoriesList = async () => {
		let res = await getChildCategoriesListAction();
		if (res.success) {
			let data = res.data;			
			setChildCategories(data);	
		}
		else
		{
			setChildCategories([]);	
		}
	}

	const inputHandler = async (e) => {
		const { name, value } = e.target;
		setbannerdata((old) => {
			return { ...old, [name]: value };
		});
	};

	const inputAdTypeHandler = async (e) => {
		const { name, value } = e.target;
		setbannerdata((old) => {
			return { ...old, [name]: value };
		});
	};
	
	const PicChange = async (e) => {
        e.preventDefault()
        let image_as_base64 = URL.createObjectURL(e.target.files[0])
        let image_as_files = e.target.files[0];		
		setbannerdata((old) => {
			return { ...old, ['ad_file']: image_as_files }
		})
        
    }
	

   const handleParentChange = (parentId) => {
		const isParentSelected = selectedpages.includes(parentId);
		let updatedCategories = [];

		if (isParentSelected) {		  
		  updatedCategories = selectedpages.filter(
			(catId) => catId !== parentId && !ChildCategoriesList.some(child => child.parent_id === parentId && child.cat_id === catId)
		  );
		} else {		  
		  updatedCategories = [
			...selectedpages,
			parentId,
			...ChildCategoriesList.filter(child => child.parent_id === parentId).map(child => child.cat_id)
		  ];
		}

		setSelectedpages(updatedCategories);
		setbannerdata((old) => {
			return { ...old, 'ad_pages': updatedCategories };
		});
	  };

	const handleChildChange = (childId) => {
		const isChildSelected = selectedpages.includes(childId);
		let updatedCategories = [];

		if (isChildSelected) {		
		  updatedCategories = selectedpages.filter((catId) => catId !== childId);
		} else {		
		  updatedCategories = [...selectedpages, childId];
		}

		setSelectedpages(updatedCategories);
		setbannerdata((old) => {
			return { ...old, 'ad_pages': updatedCategories };
		});
	  };

	
	
	function validate() {
		let ad_titleError = "";
		let ad_urlError = "";
		let ad_typeError = "";
		let onhomeError = "";
		let ad_pagesError = "";
		let ad_allpagesError = "";
		let ad_genpagesError = "";
		let ad_fileError = "";
		let ad_columnError = "";
		let statusError = "";

		bannerdata.ad_pages = selectedpages;

		if (bannerdata.ad_title === "") {
			ad_titleError = "Banner title is required.";
		}
		if (bannerdata.ad_url === "") {
			ad_urlError = "Banner URL is required.";
		}
		if (bannerdata.ad_type === "") {
			ad_typeError = "Banner is required.";
		}
		if (bannerdata.onhome === "") {
			onhomeError = "Password is required.";
		}
		if (ad_titleError || ad_urlError || ad_typeError || onhomeError ) {
			setvalidatioError({
				ad_titleError,ad_urlError,ad_typeError,onhomeError
			});
			
			return false;
		}
		else {
			setvalidatioError({
				ad_titleError,ad_urlError,ad_typeError,onhomeError
			});
			return true;
		}
	}
	const handleKeyPress = (e) => {
		// Allow only numeric values (0-9) and certain control keys
		const isValidInput = /^[0-9\b]+$/.test(e.key);

		if (!isValidInput) {
			e.preventDefault();
		}
	};
	function validateEmail(email) {
		// Regular expression for email validation
		const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
		return regex.test(email);
	}
	const createBanner = async (e) => {
		e.preventDefault()
		const isValid = validate();
		console.log(isValid);
		if (!isValid) {
		}
		else {
			setspinloader(1);
			let res = await createBannerAction(bannerdata);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}banners`;
				}, 2000);
			}
			else {
				setspinloader(0);
				toast.error(res.msg);
			}
		}
	}
	
	return (
		<>
			<div className="wrapper">
				<Header />
				<Toaster />
				<Sidebar />
				<div className="content-wrapper">
					<div className="container-full">
						<div className="content-header">
							<div className="d-flex align-items-center">
								<div className="me-auto">
									<h3 className="page-title mb-5 pb-2">Create Banner</h3>
								</div>
							</div>
							<hr />
						</div>
						{/* Content Header (Page header) */}

						{/* Main content */}
						<section className="content">
							<div className="row">
								<div className="col-lg-12 col-12">
									<div className="box">
										<div className="box-header with-border">
											<h4 className="box-title">Create Banner</h4>
											<a href={`${config.baseUrl}banners`} className="btn btn-sm btn-primary add_btn">Back</a>
										</div>
										<div className="row mt-20 mb-50 ml-15 mr-15">
											<form onSubmit={createBanner}>
												<div className="col-md-12">
													<div className="row">
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																	Ad Type <span className="req-star">*</span>
																</label>
																<div className="col-md-12">
																	<select name='ad_type' className="form-control" id='ad_typeError' onChange={inputAdTypeHandler} value={bannerdata?.ad_type}>
																		<option value="img">Image</option>
																		<option value="flash">Flash</option>
																		<option value="java">JavaScript</option>
																		<option value="iframe">Iframe</option>
																	</select>
																</div>
																<span className="validationErr">
																	{validatioError.ad_typeError}
																</span>
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																	Ad Title <span className="req-star">*</span>
																</label>
																<div className="col-md-12">
																	<input className="form-control" type="text" name="ad_title" id='ad_titleError' onChange={inputHandler} placeholder="Enter Banner title" />
																</div>
																<span className="validationErr">
																	{validatioError.ad_titleError}
																</span>
															</div>
														</div>
														{ bannerdata?.ad_type=='img'?
														<>
															<div className="col-md-12">
																<div className="form-group row mb-1">
																	<label className="col-form-label col-md-12">
																		Image File <span className="req-star">.gif, .jpg or .png only and max width 190px.</span>
																	</label>
																	<div className="col-md-12">
																		<input className="form-control" type="file" name="ad_file" id='ad_fileError'onChange={PicChange} />
																	</div>
																</div>
															</div>
														</>
														:
														bannerdata?.ad_type=='flash'?
														<>
															<div className="col-md-12">
																<div className="form-group row mb-1">
																	<label className="col-form-label col-md-12">
																		Flash File
																	</label>
																	<div className="col-md-12">
																		<input className="form-control" type="file" name="ad_file" id='ad_fileError' onChange={PicChange} />
																	</div>
																</div>
															</div>
														</>
														:
														bannerdata?.ad_type=='java'?
														<>
															<div className="col-md-12">
																<div className="form-group row mb-1">
																	<label className="col-form-label col-md-12">
																		Java Script
																	</label>
																	<div className="col-md-12">
																		<textarea className="form-control" name="javacode" id="javacodefile" rows="5" cols="50" onChange={inputHandler} />
																	</div>
																</div>
															</div>
														</>
														:
														bannerdata?.ad_type=='iframe'?
														<>
															<div className="col-md-12">
																<div className="form-group row mb-1">
																	<label className="col-form-label col-md-12">
																		Iframe(including iframe tag)
																	</label>
																	<div className="col-md-12">
																		<textarea className="form-control" name="iframecode" id="iframecode" rows="5" cols="50" onChange={inputHandler} />
																	</div>
																</div>
															</div>
														</>
														:''
														}
														
														
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																	Link to Website
																</label>
																<div className="col-md-12">
																	<input className="form-control" type="text" name="ad_url" id='ad_urlError' onChange={inputHandler} placeholder="Enter Banner URL" />
																</div>
																<span className="validationErr">
																	{validatioError.ad_urlError}
																</span>
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																	Pages area on which this ad should appear
																</label>
																<div className="col-md-12">
																	<select name='ad_column' className="form-control" id='ad_columnError' onChange={inputHandler}>
																		<option value="B">Both Columns</option>
																		<option value="L">Left Column</option>
																		<option value="F">Right Column</option>
																	</select>
																</div>
																
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																Ad should appear on Homepage or other pages expect AD detail and category pages </label>
																<div className="col-md-12">
																	<select name='onhome' className="form-control" id='onhomeError' onChange={inputHandler}>
																		<option value="1">Yes</option>
																		<option value="0">No</option>
																	</select>
																</div>
																
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																All pages including Home page (home,ad-listing pages,ad-detail page)
																</label>
																<div className="col-md-12">
																	<select name='ad_allpages' className="form-control" id='ad_allpagesError' onChange={inputHandler}>
																		<option value="1">Yes</option>
																		<option value="0">No</option>
																	</select>
																</div>
																<span className="validationErr">
																	
																</span>
															</div>
														</div>

														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12"> Generic pages (about,contact,rssfeeds,sharefriend, vipmembership) </label>
																<div className="col-md-12">
																	<select name='ad_genpages' className="form-control" id='ad_genpagesError' onChange={inputHandler}>
																		<option value="1">Yes</option>
																		<option value="0">No</option>
																	</select>
																</div>
															</div>
														</div>
														<div className="col-md-12">
														  <div className="form-group row mb-1">
															<label className="col-form-label col-md-12"> Ad Pages area on which this ad should appear </label>
															<div className="col-md-12">
															  {CategoriesList.length > 0 ?
																CategoriesList.map((row) => {
																  const isParentSelected = selectedpages.includes(row.cat_id);
																  return (
																	<div key={row.cat_id} id={"checkboxes-" + row.cat_id}>
																	  <input
																		type="checkbox"
																		name="ad_pages"
																		value={row.cat_id}
																		checked={isParentSelected}
																		onChange={() => handleParentChange(row.cat_id)}
																	  />&nbsp; {row.cat_name}
																	  <div className="ps-20">
																		{ChildCategoriesList.length > 0 ?
																		  ChildCategoriesList.map((child) => {
																			if (child.parent_id === row.cat_id) {
																			  const isChildSelected = selectedpages.includes(child.cat_id);
																			  return (
																				<div key={child.cat_id}>
																				  <input
																					className={"child-chkbox-" + row.cat_id}
																					type="checkbox"
																					name="ad_pages"
																					value={child.cat_id}
																					checked={isChildSelected}
																					onChange={() => handleChildChange(child.cat_id)}
																				  />&nbsp; {child.cat_name}
																				</div>
																			  )
																			}
																			return null;
																		  })
																		  : ''
																		}
																	  </div>
																	</div>
																  )
																})
																: ''
															  }
															</div>
														  </div>
														</div>
														
														<div className="col-md-12">
															<input type="hidden" name="ad_domins" id="domain" value="0" />

															<br />
															<div className="text-center pull-left">
																{spinloader == '0' ?
																<button className='btn btn-primary'>Submit</button>
																:
																<button disabled className='btn btn-primary'>Submiting <i className="fa fa-spinner fa-spin validat"></i></button>
																}
															</div>
														</div>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</section>
						{/* /.content */}
					</div>
				</div>

				<Footer />
			</div>
		</>
	);
};
export default Addbanner;
