import React, { useState } from 'react';
import Header from '../directives/header';
import Footer from '../directives/footer';
import Sidebar from '../directives/sidebar';
import { changePasswordAction } from '../Action/action';
import toast, { Toaster } from 'react-hot-toast';
import Cookies from 'js-cookie';
const loginData = (!Cookies.get('loginSuccessBondiAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessBondiAdmin'));

const Changepassword = () => {

    const [form, setForm] = useState({ currentPassword: '', newPassword: '', confirmPassword: '' });
	const [validatioError, setvalidatioError] 	= useState({});
	const [spinloader, setspinloader] = useState(0);  
    const inputHandler = async (e) => {
        const { name, value } = e.target
        setForm((old) => {
            return { ...old, [name]: value }
        })
      }

    function validate() {
		  let currentPassword = "";
		  let newPassword = "";
		  let confirmPassword = "";
    setvalidatioError({
			newPassword,confirmPassword,currentPassword
		  });
        if (form.currentPassword === '') {
            currentPassword = "Current password is required";            
        }
        if (form.newPassword === '') {
            newPassword = "New password is required";           
        }
        if (form.confirmPassword === '') {
            confirmPassword ="Confirm password is required";          
        }
       if (form.newPassword !== '' && form.confirmPassword !== '') { 
			if (form.newPassword !== form.confirmPassword) {
				confirmPassword ="Confirm password dose not match";           
			}
		}
		
     if (newPassword || confirmPassword || currentPassword ) {		
		  setvalidatioError({
			newPassword,confirmPassword,currentPassword
		  });
		  return false;
		} else {
		  return true;
		}
    }
     
    const SubmitForm = async (e) => {
        e.preventDefault()
        const isValid = validate();
        if (!isValid) {
    
        }
        else {
			setspinloader(1);
            form.username = loginData.username;
            let res = await changePasswordAction(form);
            if (res.success) {
                toast.success(res.msg);
                setForm((old) => {
                    return { ...old, 'currentPassword': '', 'newPassword':'', 'confirmPassword':'' }
                })
                setspinloader(0);
            } else {
                toast.error(res.msg);
                setspinloader(0);
            }
            
        }
    }

    return (
        <>
            <div className="wrapper">
                <Header />
                <Toaster />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="container-full">
                        <div className="content-header">
                            <div className="d-flex align-items-center">
                                <div className="me-auto">
                                    <h3 className="page-title mb-5 pb-2">Change password</h3>
                                </div>
                            </div>
                            <hr />
                        </div>
                        {/* Content Header (Page header) */}

                        {/* Main content */}
<section className="content">
	<div className="row">
		<div className="col-lg-12 col-12">
			<div className="box">
				<div className="box-header with-border">
					<h4 className="box-title">Change password</h4>					
				</div>
<div className="row mt-20 mb-50 ml-15 mr-15">
	<div className="col-md-12">
		<div className="row">                                               
			<div className="col-md-3">
				<div className="form-group row mb-1">
					<label className="col-form-label col-md-12">Old Password <span className="req-star">*</span></label>
					<div className="col-md-12">
						<input className="form-control" type="password" name="currentPassword" value={form.currentPassword} placeholder="Enter current password" onChange={inputHandler} />
					</div>
					<span className="validationErr">{validatioError.currentPassword}</span>
				</div>
			</div>
			<div className="col-md-3">
				<div className="form-group row mb-1">
					<label className="col-form-label col-md-12">New Password <span className="req-star">*</span></label>
					<div className="col-md-12">
						 <input className="form-control" type="password" name="newPassword" value={form.newPassword}  placeholder="Enter new password" onChange={inputHandler} />
					</div>
					<span className="validationErr">{validatioError.newPassword}</span>
				</div>
			</div>
			<div className="col-md-3">
			  <div className="form-group row mb-1">
				<label className="col-form-label col-md-12">
					Confirm Password <span className="req-star">*</span>
				</label>
				<div className="col-md-12">
					 <input className="form-control" type="password" name="confirmPassword" value={form.confirmPassword} placeholder="Enter confirm password" onChange={inputHandler} />
				</div>
				<span className="validationErr">
					{validatioError.confirmPassword}
				</span>
			</div>
			</div>			
			<div className="col-md-3">
				 <div className="form-group row mb-1 pt-28">
					<div className="col-md-12">
						{spinloader == '0' ?
						 <button type="submit" onClick={SubmitForm} className='btn btn-primary'>Submit</button>
						:
						<button disabled className='btn btn-primary' >Submiting <i className="fa fa-spinner fa-spin validat"></i></button>
						}
					</div>
					</div>
			</div>
			</div>                                                   
			</div>
</div>
</div>
</div>
</div>
</section>

</div>
</div>

                <Footer />
            </div>
        </>
    );
};
export default Changepassword;
