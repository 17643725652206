import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import toast, { Toaster } from "react-hot-toast";
import config from "../coreFIles/config";
import Cookies from 'js-cookie'
import { updateHorizontalBannerAction, getHorizontalBannerDetailAction } from "../Action/action";
import avt from '../assets/avata_profile.jpg'
import { Link, useParams } from 'react-router-dom';
const loginData = (!Cookies.get('loginSuccessBondiAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessBondiAdmin'));
const Edithorizontalbanner = () => {
	const [spinloader, setspinloader] = useState(0);
	const { id } = useParams();
	const [validatioError, setvalidatioError] = useState({});
	const [image_preview, setimage_preview] = useState('');
    const [image_file, setimage_file] = useState('');
	const [bannerdata, setbannerdata] = useState({});

	useEffect(() => {
	    if (!loginData?.email) {
            window.location.href = `${config.baseUrl}login`
        }
        
		getHorzntladDetails();
              
    }, [])     
	const getHorzntladDetails = async () => {
		let res = await getHorizontalBannerDetailAction({id:id});
		if (res.success) {
			setbannerdata(res.data);
		}
		else
		{
			setbannerdata({});	
		}
	}
	
	useEffect(() => {
		if (!loginData?.email) {
			window.location.href = `${config.baseUrl}login`
		}
		
	}, [])


	const inputHandler = async (e) => {
		const { name, value } = e.target;
		setbannerdata((old) => {
			return { ...old, [name]: value };
		});
	};

	const inputAdTypeHandler = async (e) => {
		const { name, value } = e.target;
		setbannerdata((old) => {
			return { ...old, [name]: value };
		});
	};

	const PicChange = async (e) => {
        e.preventDefault()
        let image_as_base64 = URL.createObjectURL(e.target.files[0])
        let image_as_files = e.target.files[0];
        let imageType = e.target.files[0].type;
        if (imageType == 'image/jpeg' || imageType == 'image/jpg' || imageType == 'image/png' || imageType == 'image/gif') {
            setimage_file(image_as_files);
            setimage_preview(image_as_base64);
            setbannerdata((old) => {
                return { ...old, ['ad_file']: image_as_files }
            })
        } else {
            toast.error('File type wrong please select GIF, JPG, JPEG or PNG.');
        }
    }
	
	function validate() {
		let ad_titleError = "";
		let ad_urlError = "";

		if (bannerdata.ad_title === "") {
			ad_titleError = "Banner title is required.";
		}
		if (bannerdata.ad_url === "") {
			ad_urlError = "Banner URL is required.";
		}
		
		if (ad_titleError || ad_urlError ) {
			setvalidatioError({
				ad_titleError,ad_urlError
			});
			
			console.log(bannerdata)
			
			return false;
		}
		else {
			setvalidatioError({
				ad_titleError,ad_urlError
			});
			return true;
		}
	}
	
	const updateBanner = async (e) => {
		e.preventDefault()
		const isValid = validate();
		console.log(isValid);
		if (!isValid) {
		}
		else {
			setspinloader(1);
			let res = await updateHorizontalBannerAction(bannerdata);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}horizontal-baner-list`;
				}, 2000);
			}
			else {
				toast.error(res.msg);
			}
		}
	}
	
	return (
		<>
			<div className="wrapper">
				<Header />
				<Toaster />
				<Sidebar />
				<div className="content-wrapper">
					<div className="container-full">
						<div className="content-header">
							<div className="d-flex align-items-center">
								<div className="me-auto">
									<h3 className="page-title mb-5 pb-2">Edit Horizontal Banner</h3>
								</div>
							</div>
							<hr />
						</div>
						{/* Content Header (Page header) */}

						{/* Main content */}
						<section className="content">
							<div className="row">
								<div className="col-lg-12 col-12">
									<div className="box">
										<div className="box-header with-border">
											<h4 className="box-title">Edit Horizontal Banner</h4>
											<a href={`${config.baseUrl}horizontal-baner-list`} className="btn btn-sm btn-primary add_btn">Back</a>
										</div>
										<div className="row mt-20 mb-50 ml-15 mr-15">
											<form onSubmit={updateBanner}>
												<div className="col-md-12">
													<div className="row">
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																	Ad Title <span className="req-star">*</span>
																</label>
																<div className="col-md-12">
																	<input className="form-control" type="text" name="ad_title" id='ad_titleError' onChange={inputHandler} value={bannerdata?.ad_title} />
																</div>
																<span className="validationErr">
																	{validatioError.ad_titleError}
																</span>
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																	Link to Website
																</label>
																<div className="col-md-12">
																	<input className="form-control" type="text" name="ad_url" id='ad_urlError' onChange={inputHandler} placeholder="Enter Banner URL" value={bannerdata?.ad_url} />
																</div>
															</div>
														</div>
														<div className="col-md-6">
                                                            <div className="form-group row mb-1">
                                                                <label className="col-form-label col-md-12">
                                                                    Image File <span className="req-star">.gif, .jpg or .png only and max width 540px.</span>
                                                                </label>
                                                                <div className="col-md-12">
                                                                    <input className="form-control" type="file" name="ad_file" id='ad_fileError' onChange={PicChange} />
                                                                </div>
                                                            </div>
                                                        </div>
														
														<div className="col-md-6 mt-5">		  
															<div className="sc-card-profile text-center is-invalid">
																<div className="card-media">
																	{image_preview ?
																		<img id="ad_image" src={image_preview ? image_preview : ''} alt="Horizontal Banner Ad" style={{ width: '30%' }} />
																		:
																		!bannerdata?.ad_file || bannerdata?.ad_file == null || bannerdata?.ad_file === 'null' ?
																		<img id="ad_image" src="images/noimage.png" alt="Horizontal Banner Ad" style={{ width: '30%' }} />
																		:
																		<img id="ad_image" src={`${config.bannersUrl + bannerdata?.ad_file}`} alt="Horizontal Banner Ad" style={{ width: '30%' }} />
																	}
																</div>
															</div>							
														</div>
														
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																	Ad Type
																</label>
																<div className="col-md-12">
																	<select name='ad_cat' className="form-control" id='ad_catError' onChange={inputAdTypeHandler} value={bannerdata?.ad_cat}>
																		<option value="0">Normal</option>
																		<option value="1">Personal</option>
																	</select>
																</div>
																
															</div>
														</div>
														
														{ bannerdata?.ad_cat=='1'?
														<>
                                                            <div className="col-md-6">
																<div className="form-group row mb-1">
																	<label className="col-form-label col-md-12">
																		Position
																	</label>
																	<div className="col-md-12">
                                                                        <select name='position' className="form-control" id='positionError' onChange={inputHandler} value={bannerdata?.position}>
                                                                            <option value="">Select</option>
                                                                            <option value="1">1st</option>
                                                                            <option value="2">2nd</option>
                                                                            <option value="3">3rd</option>
                                                                            <option value="4">4th</option>
                                                                        </select>
																	</div>
																</div>
															</div>
                                                        </>
                                                        :''
                                                        }
														
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																Show on home page </label>
																<div className="col-md-12">
																	<select name='ishome' className="form-control" id='ishomeError' onChange={inputHandler} value={bannerdata?.ishome}>
																		<option value="0">No</option>
																		<option value="1">Yes</option>
																	</select>
																</div>
																
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																Is Center
																</label>
																<div className="col-md-12">
																	<select name='is_center' className="form-control" id='ad_allpagesError' onChange={inputHandler}>
																		<option value="1">Yes</option>
																		<option value="0">No</option>
																	</select>
																</div>
															</div>
														</div>
														
														<div className="col-md-12">
															<input type="hidden" name="ad_domins" id="domain" value="0" />

															<br />
															<div className="text-center pull-left">
																{spinloader == '0' ?
																<button className='btn btn-primary'>Submit</button>
																:
																<button disabled className='btn btn-primary'>Submiting <i className="fa fa-spinner fa-spin validat"></i></button>
																}
															</div>
														</div>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</section>
						{/* /.content */}
					</div>
				</div>

				<Footer />
			</div>
		</>
	);
};
export default Edithorizontalbanner;