const config = {
    baseUrl: '/',   
    //~ apiUrl: "http://localhost:3008/api",
    //~ imageUrl: 'http://localhost:3008/api/uploads/',
    //~ bannersUrl: 'http://localhost:3008/api/banners/',
    apiUrl : "https://bondi.lyonsdemo.in/apis/api",
    imageUrl: 'https://bondi.lyonsdemo.in/apis/api/uploads/',
    bannersUrl: 'https://bondi.lyonsdemo.in/apis/api/banners/',
    superAdmin: 1,
    paging_size: 5
}
export default config;
