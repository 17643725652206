import "./App.css";
import React, { components } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import config from './config/config.js'
import config from "./coreFIles/config";
import Login from "./component/login";
import Changepassword from "./component/changepassword";
import Dashboard from "./component/dashboard";
import Settings from './component/Settings'
import Plans from "./component/plans";
import Addplan from "./component/addplan";
import Editplan from "./component/editplan";
import Categories from "./component/categories";
import Addcategory from "./component/addcategory";
import Editcategory from "./component/editcategory";
import Subcategory from "./component/subcategory";
import Addsubcategory from "./component/addsubcategory";
import Editsubcategory from "./component/editsubcategory";
import Users from "./component/users";
import Adduser from "./component/adduser";
import Edituser from "./component/edituser";
import Banners from "./component/banners";
import Addbanner from "./component/addbanner";
import Editbanner from "./component/editbanner";
import Horizontalbanners from "./component/horizontalbanners";
import Addhorizontalbanner from "./component/addhorizontalbanner";
import Edithorizontalbanner from "./component/edithorizontalbanner";
import Spamlist from "./component/spamlist";
import Addspam from "./component/addspam";
import Editspam from "./component/editspam";
import Adlist from "./component/adlist";
import Adcreate from "./component/createad";
import Adedit from "./component/editad";
import Contentpages from "./component/contentpages";
import Addcontent from "./component/addcontent";
import Editcontent from "./component/editcontent";
import Emaillisting from "./component/emaillisting";
import Addemailtemplate from "./component/addemailtemplate";
import Editemailtemplate from "./component/editemailtemplate";
import Sliders from "./component/sliderslist";
import Addslider from "./component/addslider";
import Editslider from "./component/editslider";
import Vipmemberlist from "./component/vipmemberslist";
import Adpaymenthistry from "./component/adpaymentshistory";



function App() {
    return (
        <BrowserRouter>
            <div>
                <Routes>
                    <Route path={`${config.baseUrl}`} element={<Login />} />
                    <Route path={`${config.baseUrl}login`} element={<Login />} />
                    <Route path={`${config.baseUrl}changepassword`} element={<Changepassword />} /> 
                    <Route path={`${config.baseUrl}dashboard`} element={<Dashboard />} />
                    <Route path={`${config.baseUrl}Settings`} element={<Settings />}          />          
                    <Route path={`${config.baseUrl}plans`} element={<Plans />} />
                    <Route path={`${config.baseUrl}add-plan`} element={<Addplan />} />
                    <Route path={`${config.baseUrl}edit-plan/:id`} element={<Editplan />} />
                    <Route path={`${config.baseUrl}categories`} element={<Categories />} />
                    <Route path={`${config.baseUrl}add-category`} element={<Addcategory />} /> 
                    <Route path={`${config.baseUrl}sub-category/:id`} element={<Subcategory />} />     
                    <Route path={`${config.baseUrl}edit-category/:id`} element={<Editcategory />} />     
                    <Route path={`${config.baseUrl}add-sub-category/:id`} element={<Addsubcategory />} />     
                    <Route path={`${config.baseUrl}edit-sub-category/:id`} element={<Editsubcategory />} />
                    <Route path={`${config.baseUrl}users`} element={<Users />} /> 
                    <Route path={`${config.baseUrl}add-user`} element={<Adduser />} /> 
                    <Route path={`${config.baseUrl}edit-user/:id`} element={<Edituser />} />  
                    <Route path={`${config.baseUrl}banners`} element={<Banners />} /> 
                    <Route path={`${config.baseUrl}add-banner`} element={<Addbanner />} /> 
                    <Route path={`${config.baseUrl}edit-banner/:id`} element={<Editbanner />} />    
                    <Route path={`${config.baseUrl}horizontal-baner-list`} element={<Horizontalbanners />} /> 
                    <Route path={`${config.baseUrl}add-horizontal-baner`} element={<Addhorizontalbanner />} /> 
                    <Route path={`${config.baseUrl}edit-horizontal-baner/:id`} element={<Edithorizontalbanner />} />
                    <Route path={`${config.baseUrl}spam-list`} element={<Spamlist />} />
                    <Route path={`${config.baseUrl}add-spamtext`} element={<Addspam />} />
                    <Route path={`${config.baseUrl}edit-spamtext/:id`} element={<Editspam />} />
                    <Route path={`${config.baseUrl}ad-list`} element={<Adlist />} />
                    <Route path={`${config.baseUrl}ad-create`} element={<Adcreate />} />
                    <Route path={`${config.baseUrl}edit-ad/:id`} element={<Adedit />} />
                    <Route path={`${config.baseUrl}content-pages`} element={<Contentpages />} />
                    <Route path={`${config.baseUrl}add-content`} element={<Addcontent />} />
                    <Route path={`${config.baseUrl}edit-content/:id`} element={<Editcontent />} />
                    <Route path={`${config.baseUrl}emaillisting`} element={<Emaillisting />} />
                    <Route path={`${config.baseUrl}add-emailtemplate`} element={<Addemailtemplate />} />
                    <Route path={`${config.baseUrl}edit-emailtemplate/:id`} element={<Editemailtemplate />} />
                     <Route path={`${config.baseUrl}sliders`} element={<Sliders />} />
                    <Route path={`${config.baseUrl}add-slider`} element={<Addslider />} />
                    <Route path={`${config.baseUrl}edit-slider/:id`} element={<Editslider />} />
                    <Route path={`${config.baseUrl}members-payment-history`} element={<Vipmemberlist />} />
                    <Route path={`${config.baseUrl}ads-payment-history`} element={<Adpaymenthistry />} />
                    
                </Routes>
            </div>
        </BrowserRouter>
    );
}

export default App;
