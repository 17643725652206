import React, { useEffect, useState } from 'react';
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import Header from '../directives/header';
import Footer from '../directives/footer';
import Sidebar from '../directives/sidebar';
import CustomPagination from './CustomPagination';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {getAdlistAction, getTotalAdListAction, adDeleteAction, adBlockUnBlockAction, getCategoriesListAction, getChildCategoriesListAction } from '../Action/action'
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
const loginData = (!Cookies.get('loginSuccessBondiAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessBondiAdmin'));

const Adlist = () => {
    const [adList, setAdList] 						= useState([]);
	const [page, setPage] 							= useState(1);	
	const [totalPages, settotalPages] 				= useState(0);
	const [totalRecords, settotalRecords] 			= useState(0);
	const [pagingspinloader, setpagingspinloader] 	= useState(0);
	const [startIndex, setstartIndex] 				= useState(0);
	const [adtypeFilter, setadtypeFilter] 			= useState('all');
	const [filterspinloader, setfilterspinloader] 	= useState(0); 
	const [CategoriesList, setCategories]			= useState([]);
	const [ChildCategoriesList, setChildCategories]	= useState([]);
	const [titleFilter, settitleFilter]				= useState('');
	const [catFilter, setcatFilter]					= useState('');
	const [subcatFilter, setsubcatFilter]			= useState('');
	const [subCatList, setsubCatList]				= useState([]);
	
    useEffect(() => {
		  if (!loginData?.email) {
            window.location.href = `${config.baseUrl}login`
        }        
        getTotalAdListAPI();
        getAdlist(); 
        categoriesList();
        childcategoriesList();
       
    }, [])
    
     
    const SetpcatFilter = async (e) => {
        setcatFilter(e.target.value);  
        let searchpCat = e.target.value; 
		if(searchpCat != null && searchpCat !=''){
			const result = ChildCategoriesList.filter(data => String(data.parent_id).includes(searchpCat)); 
			setsubCatList(result); 		
		}        
    }  	
   const SetSubcatFilter = async (e) => {
        setsubcatFilter(e.target.value);        
    } 
    
   const inputtitleFilterHandler = async (e) => {
        settitleFilter(e.target.value);        
    } 
    
    const inputadtypeFilterHandler = e => {
        const { name, value, id } = e.target        
        setadtypeFilter(value); 
    }
    
    const categoriesList = async () => {
		let res = await getCategoriesListAction();
		if (res.success) {
			let data = res.data;			
			setCategories(data);	
		}
		else
		{
			setCategories([]);	
		}
	}
	
	const childcategoriesList = async () => {
		let res = await getChildCategoriesListAction();
		if (res.success) {
			let data = res.data;			
			setChildCategories(data);	
		}
		else
		{
			setChildCategories([]);	
		}
	}
    
     const handleFilter = async () => {
	   if(adtypeFilter != '' || catFilter !=''|| subcatFilter !=''|| titleFilter !='')
	   {
		   setPage(1);
		   getAdlist();
		   getTotalAdListAPI();
	   }
	   else
	   {
		   toast.error('Please select atleast one option.');
	   }
	};
    
    const getTotalAdListAPI = async () => {
        let res = await getTotalAdListAction({title:titleFilter,p_cat:catFilter,sub_cat:subcatFilter,adtype:adtypeFilter});
        if (res.success) {
            settotalPages(res.data);
            settotalRecords(res.totalRecords);
            setpagingspinloader(0);
		}
        else
        {
			settotalPages(0);
			settotalRecords(0);
			setpagingspinloader(0);
		}
    }
   
  	const getAdlist = async () => {
		let res = await getAdlistAction({title:titleFilter,p_cat:catFilter,sub_cat:subcatFilter,adtype:adtypeFilter,page:page});
		if (res.success) {
			let data = res.data;			
			setAdList(data);	
		}
		else
		{
			setAdList([]);	
		}
	}
      
  	const adDelete = async (id) => {
		Swal.fire({
			title: 'Are you sure? ',
			text: "You want to Delete this ad text!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
		}).then(async (result) => {
			if (result.isConfirmed) {
				let res = await adDeleteAction({'id':id});
				if (res.success) {
					toast.success(res.msg);
					getAdlist();
				} else {
					toast.error(res.msg);
				}
			}
		});
	}
	
 	const adBlockUnBlock = async (id, is_active) => {
        Swal.fire({
            title: 'Are you sure?',
            text: is_active == 0 ? "You want to deactivate this ad text!”" : "You want to activate this ad text!”",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: is_active == 0 ? 'Yes, deactivate it!' : 'Yes, activate it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
				let res = await adBlockUnBlockAction({ 'id': id, 'is_active': is_active });
				if (res.success) {
					getAdlist();
					Swal.fire(
						is_active == 0 ? 'Deactivate!' : 'Activate',
						res.msg,
						'success'
					)
				} else {
					Swal.fire(
						'Failed!',
						res.msg,
						'error'
					)
				}
			}
		})
	}
	 const SrNo = (index) => {
		return startIndex + index + 1;
	  };

	const onPageChanged = async (data)  => {
		const { currentPage, totalPages, pageLimit } = data;
		const offset = (currentPage - 1) * pageLimit;
		setstartIndex(offset);	
		let res = await getAdlistAction({title:titleFilter,p_cat:catFilter,sub_cat:subcatFilter, adtype:adtypeFilter, page:offset});
		if (res.success) {
			setAdList(res.data);
		}
		else
		{
			setAdList([]);
		}
	}
	
	function getDaysleft(todate){
		var oneDay = 24 * 60 * 60 * 1000;
		var currentDate = new Date();
		todate = new Date(todate);
		
		currentDate	= moment(currentDate).format('YYYY, MM, DD');
		todate	= moment(todate).format('YYYY, MM, DD');

		currentDate	= new Date(currentDate);
		todate	= new Date(todate);
		
		var days = Math.round(Math.abs((currentDate - todate) / oneDay));
		
		if(Date.parse(currentDate) < Date.parse(todate)){
			return days;
		}
		else{
			return '-';
		}
	}
	
	

    return (
		<>
			<div className="wrapper">
				<Toaster />
				<Header />
				<Sidebar />
				<div className="content-wrapper">
					<div className="container-full">
						{/* Main content */}
						<div className="content-header">
							<div className="d-flex align-items-center">
								<div className="me-auto">
									<h3 className="page-title mb-5 pb-2">Ad List</h3>
								</div>
							</div>
							<hr />
						</div>
						<section className="content">
							<div className="row">
								<div className="col-lg-12 col-12">
									<div className="box">
										<div className="box-header with-border">
											<div className="row mb-15">
												<h4 className="box-title col-md-3">Ad List ({totalRecords})</h4>
												<div className="col-md-9 text-right">
													<a href={`${config.baseUrl}ad-create`} className="btn btn-sm btn-primary add_btn"><i className='fa fa-plus'></i> Add New Ad</a>
												</div>
											</div>
											  <div className="row">
											   <div className="col-md-2 mb-3">
												<input type="text" name='titleFilter' className="form-control lh25" id='titleFilter' onChange={inputtitleFilterHandler} value={titleFilter} placeholder="Title" />
                                             </div>
												  <div className="col-md-2 mb-3">
												<select name='adtypeFilter' className="form-control lh25" id='adtypeFilter' onChange={inputadtypeFilterHandler} value={adtypeFilter}>
													<option value="all">Select ad type</option>
													 <option value="1">Free</option>
													 <option value="0">Paid</option>
													 <option value="IsDeleted">Unpublished Ads</option>
													 <option value="isspam">Spam</option>								
												</select>
                                             </div>
                                            <div className="col-lg-4 col-xl-2 col-md-6">
                                            <select className="form-control lh25" id="p_cat_id" name="p_cat_id" onChange={SetpcatFilter} value={catFilter}>
													  <option value="">Select Category</option>
													  {
														CategoriesList.map((item)=>{
															return(
																<option value={item.cat_id}>{item.cat_name}</option>
															)
														})
														}	
														</select>
											</div>
											<div className="col-lg-4 col-xl-2 col-md-6">
											<select className="form-control lh25" id="sub_cat_id" name="sub_cat_id" onChange={SetSubcatFilter} value={subcatFilter}>
														  <option value="">Select Sub category</option>					
															{
														subCatList.map((item,index)=>{
															return(
																<option value={item.cat_id}>{item.cat_name}</option>
															)
														})
													}						
														</select>
											</div>
                                             <div className="col-md-3">	
											  <a href={`${config.baseUrl}ad-list`}
										className="btn btn-sm btn-primary add_btn">Clear <i className="fa fa-eraser" aria-hidden="true"></i></a>	
											  	{ filterspinloader == '0' ?
													  <>													  
												   <button className="btn btn-sm btn-primary add_btn mr-15" onClick={handleFilter}>Filter <i className="fa fa-filter" aria-hidden="true"></i></button>
												   </>
												   :
													<>
													 <button disabled className="btn btn-sm btn-primary add_btn mr-15" >Geting... &nbsp; <i className="fa fa-spinner fa-spin validat"></i></button>
													  </>
												  }	
											   
                                                                                        
									   </div>								  
                                        </div>
                                        
											
										</div>
										<div className="box-body">
											<div className="table-responsive">
												<table className="table table-bordered">
													<thead>
														<tr>
														<th>#</th>
														<th>Id</th>
														<th>Is Paid</th>
														<th>Name</th>
														<th>Category</th>
														<th>Plan Name</th>
														<th>Start Date</th>
														<th>End Date</th>
														<th>Days Left</th>
														<th>Action</th>
														</tr>
													</thead>
													<tbody>
													{adList.length > 0 ? (
													adList.map((item, index) => (
														<tr key={item.id}>
															<td>{SrNo(index)}</td>
															<td>{item.id}</td>
															<td>{item.plan_id === 0 ? 'Basic' : 'Paid' }</td>
															<td>
															  {item.isspam === 1 && <span className='spam'>Spam</span>} 
															&nbsp;&nbsp;  {item.title}
															</td>
															<td>{item.category}</td>
															<td>{item.plan_id === 0 ? '-' : item.plan_name }</td>
															<td>{item.plan_id === 0 ? '-' : moment(item.fromdate).format('DD/MM/YYYY') }</td>
															<td>{item.plan_id === 0 ? '-' : moment(item.toDate).format('DD/MM/YYYY') }</td>
															<td>{item.plan_id === 0 ? '-' : getDaysleft(item.toDate) }</td>
															<td>   
																<div className="btn-group mb-5">
																	{item.status === 0 ?
																		<button type="button" className="waves-effect waves-light btn btn-danger btn-sm" onClick={() => adBlockUnBlock(item.id, 1)}><i className='fa fa-unlock'></i> Inactive</button>
																		: item.status === 1 ?
																			<button type="button" className="waves-effect waves-light btn btn-primary btn-sm" onClick={() => adBlockUnBlock(item.id, 0)}><i className='fa fa-ban'></i> Active</button>
																			:
																			null
																	} &nbsp;&nbsp;&nbsp;
																	<Link to={`${config.baseUrl}edit-ad/${item.id}`}>
																		<button className='btn-sm btn-primary'><i className='fa fa-pencil'></i> Edit</button>
																	</Link>&nbsp;&nbsp;&nbsp;
																	<button type="button" className="waves-effect waves-light btn btn-danger btn-sm" onClick={() => adDelete(item.id)}><i className='fa fa-trash'></i> Delete</button>
																</div>
															</td>
														</tr>
													))
												) : (
													<tr>
														<td colSpan="10">No record found.</td>
													</tr>
												)}

												</tbody>
												</table>	
												{totalPages > 0 ?										  
												<CustomPagination totalRecords={totalRecords} totalPages={totalPages} pageNeighbours={page} onPageChanged={onPageChanged}   />
												: ''
											}	
																				
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>                       
					</div>
				</div>      	
				<Footer />
			</div>
		</>
    )

}
export default Adlist;
